import React from 'react'
import { PageProps } from 'gatsby'

// Components
import Cta from './Cta'
import CtaDownload from './CtaDownload'

export interface CtaProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Cta
  location: PageProps['location']
}

interface PostProps {
  [key: string]: React.FC<CtaProps>
}

const CtaShell = ({ fields, location }: CtaProps) => {
  const types: PostProps = {
    default: Cta,
    download: CtaDownload,
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default CtaShell
