import React from 'react'
import { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Plaatjie from '@ubo/plaatjie'

interface GalleryProps {
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Gallery
}

const Gallery = ({ fields }: GalleryProps) => (
  <section className="mt-12 mb-20">
    <Swiper
      breakpoints={{
        0: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 2.6,
          spaceBetween: 20,
        },
      }}
      modules={[Navigation]}
      navigation={{
        prevEl: '.custom-swiper-button-prev',
        nextEl: '.custom-swiper-button-next',
      }}
    >
      {fields.gallery?.map((node: any, index: number) => (
        <SwiperSlide key={index} className="h-auto">
          <Plaatjie image={node} alt="" />
        </SwiperSlide>
      ))}
    </Swiper>
  </section>
)

export default Gallery
