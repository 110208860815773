import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Plaatjie from '@ubo/plaatjie'

// Images
import Logo from 'img/logo.inline.svg'
import Phone from 'img/phone.inline.svg'
import Email from 'img/email.inline.svg'

// Components
import Button from 'components/elements/Button'
import ParseContent from 'components/shared/ParseContent'
import NavLink from 'components/shared/NavLink'
import FormMalfunction from 'components/elements/FormMalfunction'

const Footer = () => {
  const [formOpen, setFormOpen] = useState(false)

  // eslint-disable-next-line
  const { fields } = useStaticQuery<GatsbyTypes.footerQueryQuery>(graphql`
    query footerQuery {
      fields: wpComponent(databaseId: { eq: 98 }) {
        footer {
          infoFooter {
            address
            phone {
              title
              url
            }
            email {
              title
              url
            }
          }
          menuFooter {
            menu {
              link {
                title
                url
              }
            }
          }
          iWantFooter {
            title
            links {
              link {
                title
                url
              }
            }
          }
          bottomFooter {
            documents {
              link {
                title
                url
              }
            }
            highlighted {
              link {
                title
                url
              }
              image {
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(quality: 100, width: 400)
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <footer className="bg-secondary text-light">
      <div className="container flex h-full flex-col items-end justify-between py-10 md:flex-row xl:py-20">
        <div className="h-full w-full xl:w-1/4">
          <Button aria-label="Home" className="pl-5" to="/">
            <Logo className="h-[66px]" />
          </Button>
          <ParseContent
            content={fields?.footer?.infoFooter?.address || ''}
            className="py-10 children:text-sm"
          />
          <div>
            <a
              href={fields?.footer?.infoFooter?.phone?.url || '#'}
              className="flex items-center hover:text-primary"
            >
              <Phone className="mr-3 mt-1" />
              <span className="text-sm">
                {fields?.footer?.infoFooter?.phone?.title}
              </span>
            </a>
            <a
              href={fields?.footer?.infoFooter?.email?.url || '#'}
              className="flex items-center  hover:text-primary"
            >
              <Email className="mr-3 mt-1" />
              <span className="text-sm">
                {fields?.footer?.infoFooter?.email?.title}
              </span>
            </a>
          </div>
        </div>
        <div className="mt-10 flex w-full xl:mt-0 xl:w-2/5">
          {fields?.footer?.menuFooter?.map((column, columnIndex) => (
            <div key={columnIndex}>
              {column?.menu?.map((item, itemIndex) => (
                <>
                  {columnIndex !== 1 && (
                    <div className="mr-10 xl:mx-16" key={itemIndex}>
                      <NavLink
                        to={item?.link?.url || '#'}
                        className="text-sm !font-normal !text-light hover:text-primary"
                      >
                        {item?.link?.title}
                      </NavLink>
                    </div>
                  )}

                  {columnIndex === 1 && (
                    <div className="mr-10 xl:mx-16" key={itemIndex}>
                      <a
                        className="text-sm !font-normal !text-light hover:text-primary"
                        role="button"
                        onClick={() => {
                          if (item?.link?.url?.includes('garage')) {
                            if (typeof localStorage !== 'undefined') {
                              localStorage.setItem(
                                'garagedoor-overview-filters',
                                JSON.stringify([
                                  'dGVybToyMTU=',
                                  'dGVybToyMTg=',
                                  'dGVybToyMDk=',
                                  'dGVybToyMTI=',
                                ])
                              )
                            }
                          } else {
                            if (typeof localStorage !== 'undefined') {
                              localStorage.setItem(
                                'garagedoor-overview-filters',
                                ''
                              )
                            }
                          }

                          window.location.href = item?.link?.url || '/'

                          setTimeout(() => {
                            window.location.reload()
                          }, 100)
                        }}
                      >
                        {item?.link?.title}
                      </a>
                    </div>
                  )}
                </>
              ))}
            </div>
          ))}
        </div>
        <div className="relative mt-10 border-2 border-primary py-2 pl-12 pr-4 xl:mt-0 xl:w-1/4 xl:px-16 xl:py-12">
          <div className="absolute top-4 -left-12 bg-secondary p-4">
            <h2 className="font-primary text-2xl font-bold tracking-wide">
              {fields?.footer?.iWantFooter?.title}
            </h2>
          </div>
          {fields?.footer?.iWantFooter?.links?.map((node, linkIndex) => {
            if (node?.link?.url?.includes('storing-melden')) {
              return (
                <div key={linkIndex}>
                  <div
                    className="font-bold hover:text-primary children:text-sm sm:children:text-lg"
                    onClick={() => setFormOpen(true)}
                    role="button"
                  >
                    <span>&#8250;</span>
                    <span className="ml-3">{node?.link?.title}</span>
                  </div>
                </div>
              )
            }
            return (
              <div key={linkIndex}>
                <NavLink
                  to={node?.link?.url || '#'}
                  className="font-bold hover:text-primary children:text-sm sm:children:text-lg"
                >
                  <span>&#8250;</span>
                  <span className="ml-3">{node?.link?.title}</span>
                </NavLink>
              </div>
            )
          })}
        </div>
      </div>
      <div className="container flex flex-col items-center justify-between pb-5 sm:flex-row sm:pb-0">
        <div className="flex py-4">
          {fields?.footer?.bottomFooter?.documents?.map(
            (document, docIndex) => (
              <NavLink
                to={document?.link?.url || '#'}
                key={docIndex}
                className="mr-2 text-xs opacity-50 hover:underline sm:mr-8 md:text-lg"
              >
                {document?.link?.title}
              </NavLink>
            )
          )}
        </div>
        <a
          href={fields?.footer?.bottomFooter?.highlighted?.link?.url || '#'}
          className="flex items-center text-center"
          target="_blank"
        >
          <span>{fields?.footer?.bottomFooter?.highlighted?.link?.title}</span>
          <Plaatjie
            image={fields?.footer?.bottomFooter?.highlighted?.image}
            alt=""
            className="ml-4 mt-1"
          />
        </a>
      </div>
      <FormMalfunction
        description="<h2 style='text-align: center;'><strong>IK WIL EEN STORING MELDEN</strong></h2>
        <p style='text-align: center;'>
        Vul hieronder het formulier in en wij nemen binnen 2 uur contact met u. Zo zorgen we ervoor dat de storing zo snel mogelijk wordt opgelost.
        </p>
        "
        isOpen={formOpen}
        closeModal={() => setFormOpen(false)}
      />
    </footer>
  )
}

export default Footer
