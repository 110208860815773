import React from 'react'
import Button from 'components/elements/Button'
import Plaatjie from '@ubo/plaatjie'

// Images
import Check from 'img/check-rounded.inline.svg'

// Components
import ParseContent from 'components/shared/ParseContent'

// Interface
import { HeroProps } from './HeroShell'

const HeroCta = ({ fields }: HeroProps) => {
  return (
    <section id="HeroCta" className="relative text-light">
      <div className="bg-secondary lg:pb-32">
        <div className="container relative z-30 flex flex-col items-end justify-center py-16">
          <div className="flex flex-col lg:w-11/12 lg:flex-row">
            <div className="mb-10 flex flex-col justify-between lg:mt-20 lg:mb-0 lg:w-1/2">
              {fields.usps?.map((usp, uspIndex) => (
                <div className="flex items-center" key={uspIndex}>
                  <Check />
                  <span className="ml-3 mb-1 text-2xl">{usp?.link?.title}</span>
                </div>
              ))}
            </div>
            <div className="mb-10 lg:mb-0 lg:w-1/2">
              <ParseContent
                content={fields.description || ''}
                className="children-h2:font-secondary children-p:leading-relaxed"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="relative w-full lg:pb-[30%]">
        <div className="bottom-0 flex w-full justify-center lg:absolute lg:top-0">
          <Plaatjie
            image={fields.background}
            alt=""
            className="mx-auto max-h-[904px]"
          />
        </div>

        <div className="container relative flex justify-end">
          <div className="-mt-12 grid gap-x-16 lg:mt-20 lg:w-11/12 lg:grid-cols-2">
            {fields.links?.map((node, linkIndex) => (
              <div
                className="mb-8 bg-light px-6 pb-8 shadow-lg lg:col-span-1 lg:-mt-48 lg:mb-0"
                key={linkIndex}
              >
                <div className="p-6">
                  <ParseContent
                    content={node?.description || ''}
                    className="text-secondary children-h2:font-secondary lg:children-h2:text-4xl"
                  />
                  <div className="mt-12">
                    <Button to={node?.link?.url || '#'} className="btn-primary">
                      {node?.link?.title}
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroCta
