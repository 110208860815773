import React from 'react'
import { graphql, PageProps } from 'gatsby'

// Components
import SEO, { SeoProps } from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler from 'components/shared/FlexContentHandler'

export interface GaragedoorContextProps {
  exists: boolean
  title: string
  translations: []
  locale: {
    locale: string
  }
  languages: []

  profiling: any
  doorTypes: any
}

export const GaragedoorContext = React.createContext({
  exists: false,
  title: '',
  translations: [],
  locale: {
    locale: '',
  },
  languages: [],
  profiling: [],
  doorTypes: [],
})

interface PageTemplateProps extends Omit<PageProps, 'data'> {
  data: {
    site: {
      languages: []
    }
    page: {
      databaseId: number
      title?: string
      path?: string
      seo: SeoProps
      flexContent: GatsbyTypes.Maybe<GatsbyTypes.WpFragment_FlexFragment>
      translations?: any
      locale: {
        locale: string
      }
      profiling: any
      doorTypes: any
    }
  }
  prefix?: string
}

const PageTemplate = ({
  data: {
    site,
    page: {
      title = '',
      flexContent,
      seo,
      translations,
      locale,
      profiling,
      doorTypes,
    },
  },
  pageContext,
  location,
  prefix = 'Garagedoor_Flexcontent',
}: PageTemplateProps) => {
  return (
    <GaragedoorContext.Provider
      value={{
        exists: true,
        title,
        translations,
        locale,
        languages: typeof site !== 'undefined' ? site.languages : [],
        profiling,
        doorTypes,
      }}
    >
      <Layout>
        <SEO seo={seo} />
        <FlexContentHandler
          prefix={prefix}
          fields={flexContent}
          location={location}
          pageContext={pageContext}
        />
      </Layout>
    </GaragedoorContext.Provider>
  )
}

export const pageQuery = graphql`
  query garagedoorById($pageId: String!) {
    page: wpGaragedoor(id: { eq: $pageId }) {
      ...generalGaragedoorFragment
    }
  }
`

export default PageTemplate
