import React from 'react'

// Duuf
import { FormDuufField, FormDuufGroup } from '@ubo/form-duuf'

// Interface
import StepHandler from '../Handlers/StepHandler'

// Interface
import { useFormDuuf } from '@ubo/form-duuf'
import useConfigurator from '../Hooks/useConfigurator'

const Five = () => {
  const config = useConfigurator()
  const duuf = useFormDuuf()
  const enabled = !!(
    duuf.formik.values.input_9 &&
    duuf.formik.values.input_10 &&
    duuf.formik.values.input_16 &&
    duuf.formik.values.input_17 &&
    duuf.formik.values.input_19
  )

  React.useEffect(() => {
    duuf.formik.setErrors({})
  }, [])

  return (
    <div className="mt-16">
      <h2 className="mb-4 text-2xl font-bold">
        {config.quotation ? '' : '5. '}Gegevens
      </h2>
      <div>
        <div className="flex">
          <div className="mr-2 w-1/2">
            <FormDuufField id={9} />
          </div>
          <div className="ml-2 w-1/2">
            <FormDuufField id={10} />
          </div>
        </div>
        <div className="flex">
          <div className="w-2/3">
            <FormDuufField id={11} />
          </div>
          <div className="ml-3 w-1/3">
            <FormDuufField id={12} />
          </div>
        </div>
        <div className="flex">
          <div className="w-2/5">
            <FormDuufField id={13} />
          </div>
          <div className="ml-3 w-3/5">
            <FormDuufField id={14} />
          </div>
        </div>
        <div className="mt-6">
          <FormDuufGroup showIds={[16, 17]} />
        </div>
        <div className="mt-10">
          <FormDuufGroup showIds={[18, 19]} />
        </div>
      </div>
      <StepHandler step={4} enabled={enabled} />
    </div>
  )
}

export default Five
