import React from 'react'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'
import Button from 'components/elements/Button'

// Interface
import { CtaProps } from './CtaShell'

const CtaDownload = ({ fields }: CtaProps) => (
  <section id="CtaDownload" className="relative mb-10 lg:mb-20">
    <div className="container pt-10 lg:pt-20 ">
      <div className="mx-auto sm:w-3/4">
        <div className="grid sm:grid-cols-3">
          <div className="sm:col-span-1">
            <Plaatjie
              image={fields.image}
              alt=""
              className="relative h-[240px] w-full sm:h-[260px] sm:w-full md:h-full lg:left-16 lg:w-[180px]"
            />
          </div>
          <div className="flex items-center sm:col-span-2">
            <div className="min-h-[318px] w-full bg-greyLight py-10 pl-10 pr-4 lg:min-w-[684px] lg:pl-24">
              <ParseContent
                content={fields.description || ''}
                className="children-h2:pb-0 children-h2:text-4xl children-h2:leading-tight"
              />
              <div className="mt-10">
                <Button
                  to={fields.download?.localFile?.publicURL || '#'}
                  target="_blank"
                  className="btn-primary"
                >
                  Download brochure
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default CtaDownload
