import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'
import Employee from 'components/elements/Employee'

// Interface
import { TextProps } from 'components/flex/Text/TextShell'

const TextPerson = ({ fields }: TextProps) => (
  <section id="TextPerson">
    <div className="container flex justify-end py-16">
      <div className="w-11/12">
        <div className="grid grid-cols-4">
          <div className="col-span-4 lg:col-span-2">
            <ParseContent content={fields.description || ''} />
          </div>
          <div className="col-span-4 flex justify-end lg:col-span-2">
            <div>
              <Employee fields={fields.employee} showInfo />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default TextPerson
