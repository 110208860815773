import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import BlogBink, { useBlogBink, BlogBinkPosts } from '@ubo/blog-bink'

// Components
import BlogGridPost from 'components/elements/Posts/BlogGridPost'

// Interface
import { PostsProps } from 'components/flex/Posts/PostsShell'

const Highlighted = ({ fields }: PostsProps) => {
  const {
    allWpPost,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.highlightedQueryQuery>(graphql`
    query highlightedQuery {
      allWpPost(sort: { order: ASC, fields: date }) {
        edges {
          node {
            ...generalPostFragment
          }
        }
      }
    }
  `)

  const posts: unknown = allWpPost.edges
  const postIds = fields.posts?.map((post) => post?.databaseId)

  return (
    <section id="Highlighted">
      <BlogBink
        showIds={postIds as Array<number>}
        posts={posts as BlogBinkPosts}
        id="highlighted-blog"
        limit={Infinity}
      >
        <div className="py-5">
          <BlogGrid fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

const BlogGrid = ({ fields }: PostsProps) => {
  const blogBink = useBlogBink()

  const [marginLeft, setMarginLeft] = React.useState<number | null>(null)
  const containerRef = React.useRef(null)

  const handleResize = () => {
    if (!containerRef.current) {
      return
    }

    const nodeStyle = window.getComputedStyle(containerRef.current)
    const nodeMarginRight = nodeStyle.getPropertyValue('margin-left')

    setMarginLeft(Number(nodeMarginRight.replace('px', '')))
  }

  React.useEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', () => handleResize)
    }
  }, [])

  return (
    <>
      <div className="container" ref={containerRef}>
        <div className="hidden grid-cols-3 gap-4 lg:grid">
          {blogBink.posts.map((post, index) => {
            const { node }: any = post

            return (
              <div key={`post-${index}-${post.node.id}`} className="col-span-1">
                <BlogGridPost
                  key={post.node.id}
                  node={node}
                  blogFields={fields}
                />
              </div>
            )
          })}
        </div>
      </div>

      {marginLeft !== null && (
        <div
          className="container relative lg:hidden"
          style={{
            width: `calc(100% - ${marginLeft}px + 0.75rem) !important`,
            marginLeft: `${marginLeft}px !important`,
          }}
        >
          <Swiper
            className="relative pr-[10px]"
            breakpoints={{
              768: {
                slidesPerView: 2.25,
                spaceBetween: 30,
              },
              450: {
                slidesPerView: 1.75,
                spaceBetween: 20,
              },
              0: {
                slidesPerView: 1.25,
                spaceBetween: 10,
              },
            }}
            modules={[Pagination]}
          >
            {blogBink.posts.map((post, index) => {
              const { node }: any = post

              return (
                <SwiperSlide
                  className="h-full"
                  key={`post-${index}-${post.node.id}`}
                >
                  <BlogGridPost
                    key={post.node.id}
                    node={node}
                    blogFields={fields}
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      )}
    </>
  )
}

export default Highlighted
