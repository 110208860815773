import React, { ReactNode } from 'react'
import { PageProps } from 'gatsby'

// Components
import Text from 'components/flex/Text/Text'
import TextLinks from 'components/flex/Text/TextLinks'
import TextPerson from 'components/flex/Text/TextPerson'
import TextCta from 'components/flex/Text/TextCta'
import TextTitleImage from 'components/flex/Text/TextTitleImage'
import TextImage from './TextImage'

export interface TextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
  location: PageProps['location']
}

interface PostProps {
  [key: string]: React.FC<TextProps>
}

const TextShell = ({ fields, location }: TextProps) => {
  const types: PostProps = {
    standard: Text,
    links: TextLinks,
    person: TextPerson,
    titleimage: TextTitleImage,
    image: TextImage,
    cta: TextCta,
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default TextShell
