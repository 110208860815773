import React, { Fragment } from 'react'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'
import Button from 'components/elements/Button'

// Images
import Check from 'img/check-rounded.inline.svg'

// Interface
import { BannerProps } from 'components/flex/Banner/BannerShell'

const BannerLanding = ({ fields }: BannerProps) => (
  <section
    id="BannerLanding"
    className="relative flex flex-col justify-between xl:h-[970px]"
  >
    <Plaatjie
      className="!absolute top-0 left-0 -z-10 h-full w-full"
      alt="banner"
      image={fields?.image}
      loading="eager"
    />
    <div className="absolute -z-10 h-full w-full bg-layer-dark mix-blend-multiply" />
    <div className="container flex justify-center">
      <div className="w-full lg:w-4/5">
        <div className="w-full pt-36 lg:w-4/6 xl:w-3/6 xl:pt-72">
          <ParseContent
            className="children-h1 text-light children:drop-shadow-md lg:children-p:pr-5"
            content={fields.description || ''}
          />
          <div className="mt-10 flex flex-col xs:flex-row">
            {fields.links?.map((node, index) => (
              <Fragment key={index}>
                {node?.buttontype === 'primary' && (
                  <Button
                    className="btn-primary mr-5 mb-5 flex items-center"
                    to={node?.link?.url || '#'}
                  >
                    <Plaatjie image={node.icon} alt="" className="mr-3" />
                    <span className="mb-1">{node.link?.title}</span>
                  </Button>
                )}
                {node?.buttontype === 'primaryoutline' && (
                  <Button
                    className="btn-primary-outline mb-5 mr-5 flex items-center"
                    to={node?.link?.url || '#'}
                  >
                    <Plaatjie image={node.icon} alt="" className="mr-3" />
                    <span className="mb-1">{node.link?.title}</span>
                  </Button>
                )}
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
    <div className="flex justify-end">
      <div className="mt-10 bg-secondary py-6 px-6 sm:px-10 lg:w-2/5 lg:px-20 lg:py-12">
        {fields.usps?.map((usp, index) => (
          <div className="mb-2 flex items-center text-light" key={index}>
            <Check />
            <span className="ml-4 mb-1 font-bold xl:text-2xl">
              {usp?.title}
            </span>
          </div>
        ))}
      </div>
    </div>
  </section>
)

export default BannerLanding
