import { useContext } from 'react'

import { ConfiguratorContext, ConfiguratorContextProps } from '..'

const useConfigurator = () => {
  const context = useContext(ConfiguratorContext)

  return context as ConfiguratorContextProps
}

export default useConfigurator
