import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'
import Crumb from 'components/elements/Breadcrumb'

// Interface
import { BannerProps } from 'components/flex/Banner/BannerShell'

const BannerDefault = ({ fields, location, pageContext }: BannerProps) => (
  <section id="BannerDefault">
    <div className="bg-dark">
      <div className="container flex pt-60 pb-16 lg:justify-end">
        <div className="lg:w-11/12">
          <ParseContent
            className="text-light children:!pb-0"
            content={fields.description || ''}
          />
          <Crumb location={location} pageContext={pageContext} />
        </div>
      </div>
    </div>
  </section>
)

export default BannerDefault
