/* eslint-disable import/no-unresolved */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Images
import Logo from 'img/logo.inline.svg'

// Components
import Button from 'components/elements/Button'
import Mobile from 'components/layout/Header/Mobile'
import Desktop from 'components/layout/Header/Desktop'
import Topmenu from './Topmenu'

interface HeaderProps {
  setIsScrollable: Function
  isSticky?: boolean
}

const Header = ({ setIsScrollable, isSticky = false }: HeaderProps) => {
  // eslint-disable-next-line
  const query = useStaticQuery<GatsbyTypes.headerQueryQuery>(graphql`
    query headerQuery {
      wpComponent(databaseId: { eq: 97 }) {
        header {
          menuHeader {
            link {
              title
              url
            }
            submenu {
              mainlink {
                title
                url
              }
              sublinks {
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(quality: 100, width: 800)
                    }
                  }
                }
                link {
                  title
                  url
                }
                sale
              }
            }
            highlighted {
              icon {
                localFile {
                  publicURL
                }
              }
              button
            }
          }
          topmenuHeader {
            highlighted {
              link {
                title
                url
              }
              image {
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(quality: 100, width: 400)
                  }
                }
              }
            }
            phone {
              title
              url
            }
            malfunction {
              title
              url
            }
            malfunctiondescription
          }
        }
      }
    }
  `)

  const fields: any = query.wpComponent?.header?.menuHeader
  const topbar: any = query.wpComponent?.header?.topmenuHeader

  return (
    <>
      <div
        className={`fixed top-0 left-0 z-30 w-full bg-gradient-to-b from-gradient-darkFrom via-gradient-darkVia to-gradient-darkTo transition-all ${
          isSticky ? `h-[120px] ` : `h-[240px]`
        }`}
      />
      <nav className="fixed z-50 w-full">
        <Topmenu fields={topbar} isSticky={isSticky} />
        <div
          className={`${
            isSticky ? 'h-[70px] bg-secondary' : 'h-[100px]'
          } transition-all`}
        >
          <div className="container flex h-full items-center justify-between">
            <Button
              aria-label="Home"
              className="relative z-50 hover:opacity-75"
              to="/"
            >
              <Logo
                className={`${
                  isSticky
                    ? 'h-[40px] w-[113px] lg:h-[50px] lg:w-full'
                    : 'h-[50px] w-[141px] lg:h-[66px] lg:w-full'
                }`}
              />
            </Button>

            <Mobile
              fields={fields}
              setIsScrollable={setIsScrollable}
              isSticky={isSticky}
            />

            <Desktop fields={fields} isSticky={isSticky} />
          </div>
        </div>
      </nav>
    </>
  )
}

export default Header
