import React from 'react'
import Plaatjie from '@ubo/plaatjie'

// Components
import Button from 'components/elements/Button'

// Images
import Settings from 'img/settings.inline.svg'
import Check from 'img/check-rounded.inline.svg'

interface GaragedoorPostProps {
  key: string
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_Garagedoorz
  node: GatsbyTypes.WpGaragedoor
}

const GaragedoorPost = ({ key, node, blogFields }: GaragedoorPostProps) => {
  let uri = node.uri
  if (node.recap?.sorting === 'particulier') {
    uri = uri?.replace('industrie', 'woning')
  }

  console.log(node)

  return (
    <div key={key} className="flex h-full flex-col justify-between  bg-light">
      <Plaatjie
        image={node.recap?.thumbnail}
        alt=""
        className="h-[200px] sm:h-[260px]"
      />
      <div className="p-8">
        <div>
          <h2 className="font-primary text-3xl font-bold">
            {node.title?.toUpperCase()}
          </h2>
          <ul className="my-6 sm:pl-6">
            {node.recap?.benefits?.map((benefit, index) => (
              <li key={index} className="mb-6 flex items-center">
                <Check />
                <span className="ml-2">{benefit?.title}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-8 flex flex-col justify-around sm:flex-row">
          <div>
            {node.title === 'Sectionaaldeuren' ? (
              <Button
                className="btn-secondary mb-4 flex items-center sm:mb-0"
                to={'/deur-samenstellen' || '#'}
              >
                <Settings />
                <span className="ml-2 mb-1">Deur samenstellen</span>
              </Button>
            ) : (
              <Button
                className="btn-secondary mb-4 flex items-center sm:mb-0"
                to={'/contact' || '#'}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  aria-hidden="true"
                  role="img"
                  className="iconify iconify--nimbus"
                  width="26"
                  height="22"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill="#888888"
                    d="M5.44 7.47h5.26v1.25H5.44zm0 2.36h5.26v1.25H5.44zm0-4.76h5.26v1.25H5.44z"
                  ></path>
                  <path
                    fill="currentColor"
                    d="M11.34 1L9.64.28L8.08 1L6.41.28L4.84 1L2.46 0v16l2.38-1l1.57.69L8.08 15l1.56.69l1.7-.69l2.2 1V0zm.94 13.11l-.92-.41l-1.69.69l-1.57-.72l-1.68.69l-1.55-.69l-1.15.47V1.86l1.15.47l1.55-.69l1.68.69l1.57-.69l1.69.69l.92-.41z"
                  ></path>
                </svg>
                <span className="ml-2 mb-1">Offerte aanvragen</span>
              </Button>
            )}
          </div>
          <Button className="btn-secondary-outline" to={uri || '#'}>
            <span>{blogFields.readmoretext}</span>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default GaragedoorPost
