import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'

// Interface
import { TextProps } from 'components/flex/Text/TextShell'

const Text = ({ fields }: TextProps) => (
  <section id="Text">
    <div className="container py-5">
      <div className="grid grid-cols-12">
        <div className="col-span-8 ml-28">
          <ParseContent content={fields.description || ''} />
        </div>
      </div>
    </div>
  </section>
)

export default Text
