import React, { createContext, useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Duuf
import FormDuuf from 'components/related/Form'

// Components
import Main from './Main'

// CSS
import 'styles/configurator.css'
import ParseContent from 'components/shared/ParseContent'
import Button from 'components/elements/Button'
import useConfigurator from './Hooks/useConfigurator'

export interface ConfiguratorContextProps {
  reset: () => void
  setCurrentStep: (number: number) => void
  currentStep: number
  setStepsCompleted: (number?: number) => void
  stepsCompleted: number[]
  setQuotation: (quotation: boolean) => void
  quotation: boolean
  startUrl: string
}

export const ConfiguratorContext = createContext<ConfiguratorContextProps>({
  reset: () => null,
  setCurrentStep: (step) => step,
  currentStep: 0,
  setStepsCompleted: (step) => step,
  stepsCompleted: [],
  setQuotation: (quotation) => quotation,
  quotation: false,
  startUrl: '',
})

const Configurator = () => {
  const {
    site: {
      // @ts-ignore
      siteMetadata: { siteUrl },
    },
  } = useStaticQuery<GatsbyTypes.previewConfiguratorQueryQuery>(graphql`
    query previewConfiguratorQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  const startUrl = process.env.NODE_ENV === 'production' ? siteUrl : ''

  const [currentStep, setCurrentStep] = useState(0)
  const [stepsCompleted, setStepsCompleted] = useState<number[]>([])
  const [quotation, setQuotation] = useState(true)

  useEffect(() => {
    const currentStepLocal = localStorage.getItem('current-step')
    const stepsCompletedLocal = localStorage.getItem('steps-completed')
    const quotationLocal = localStorage.getItem('quotation')

    if (currentStepLocal) {
      setCurrentStep(JSON.parse(currentStepLocal))
    }

    if (stepsCompletedLocal) {
      setStepsCompleted(JSON.parse(stepsCompletedLocal))
    }

    if (quotationLocal) {
      setQuotation(JSON.parse(quotationLocal))
    }
  }, [])

  const setStepsCompletedLocal = (step?: number) => {
    if (step !== 0 && !step) {
      setStepsCompleted([])
      localStorage.setItem('steps-completed', '')
    }

    if (step !== undefined && stepsCompleted.indexOf(step) === -1) {
      const newStepsCompleted = [...stepsCompleted]
      newStepsCompleted.push(step)
      setStepsCompleted(newStepsCompleted)

      localStorage.setItem('steps-completed', JSON.stringify(newStepsCompleted))
    }
  }

  const setCurrentStepLocal = (step: number) => {
    localStorage.setItem('current-step', JSON.stringify(step))
    setCurrentStep(step)

    setStepsCompletedLocal(step)
  }

  const setQuotationLocal = (quotation: boolean) => {
    localStorage.setItem('quotation', JSON.stringify(quotation))
    setQuotation(quotation)
  }

  const reset = () => {
    setCurrentStepLocal(0)
    setQuotationLocal(false)
    setStepsCompletedLocal()
  }

  return (
    <section>
      <div className="container py-4 text-center text-sm md:hidden">
        Let op: de configurator werkt het beste op desktop (groot beeldscherm of
        laptop).
      </div>
      <div className="container relative flex justify-center">
        <div className="mt-20 lg:w-10/12">
          <ConfiguratorContext.Provider
            value={{
              reset,
              setCurrentStep: setCurrentStepLocal,
              currentStep,
              setQuotation: setQuotationLocal,
              quotation,
              setStepsCompleted: setStepsCompletedLocal,
              stepsCompleted,
              startUrl,
            }}
          >
            <FormDuuf
              id={4}
              generate={false}
              privacyUrlPlacement="Algemene Voorwaarden"
              privacyUrl="#"
              renderStatus={({ status, content }) => (
                <Status status={status} content={content || ''} />
              )}
            >
              <Main currentStep={currentStep} />
            </FormDuuf>
          </ConfiguratorContext.Provider>
        </div>
      </div>
    </section>
  )
}

function Status({ status, content }: { status: string; content: string }) {
  const config = useConfigurator()

  React.useEffect(() => {
    if (status !== 'done') {
      return
    }

    config.reset()
  }, [status])

  return (
    <>
      <ParseContent content={content} />
      {status === 'done' && (
        <div className="flex items-center md:mt-32">
          <Button className="btn-primary" to={'/'}>
            Naar homepagina
          </Button>
        </div>
      )}
    </>
  )
}

export default Configurator
