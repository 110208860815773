/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

// Components
import Button from 'components/elements/Button'

// Third Party
import parse, { domToReact } from 'html-react-parser'

const findAndReplace = (content: string) =>
  parse(content, {
    replace: (domNode) => {
      const { name, attribs, children }: any = domNode

      if (name === 'a') {
        const { href, ...rest } = attribs

        return (
          <Button to={href} {...rest}>
            {domToReact(children, {})}
          </Button>
        )
      }

      return null
    },
  })

interface ParseContentProps {
  content: string
  className?: string
}

const ParseContent: React.FC<ParseContentProps> = ({
  content,
  className = '',
}) => {
  if (!content) {
    return null
  }

  return (
    <div className={`${className} parsecontent`}>{findAndReplace(content)}</div>
  )
}

export default ParseContent
