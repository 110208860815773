import React from 'react'

// Components
import StepHandler from '../Handlers/StepHandler'
import RadioFieldImage from '../Fields/RadioFieldImage'

// Functions
import { generateActiveColors } from '../Functions/generateActiveColors'

// Interface
import { useFormDuuf } from '@ubo/form-duuf'

const Three = () => {
  const duuf = useFormDuuf()
  const enabled = !!(duuf.formik.values.input_6 && duuf.formik.values.input_7)

  React.useEffect(() => {
    if (duuf.formik.values.input_7) {
      const colors = generateActiveColors(8, duuf.formik.values)

      const hasColor = colors?.find(
        (color: any) => color.value === duuf.formik.values.input_8
      )

      if (!hasColor) {
        duuf.formik.setFieldValue(
          'input_8',
          colors.length > 0 ? colors[0].value : '',
          false
        )
      }
    }
  }, [duuf.formik.values.input_7])

  return (
    <div className="mt-20">
      <h2 className="text-2xl font-bold">
        3. Motief<span className="form-duuf-label-mandatory">*</span>
      </h2>
      <div>
        <RadioFieldImage
          id={6}
          type="square"
          extension="png"
          imgFolder="profiling"
        />
        {duuf.formik.values.input_6 && (
          <RadioFieldImage
            id={7}
            type="square"
            extension="png"
            imgFolder="surface"
          />
        )}
      </div>
      <StepHandler step={3} enabled={enabled} />
    </div>
  )
}

export default Three
