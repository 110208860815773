import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'

// Images
import Logo from 'img/logo.inline.svg'

// Components
import Menu from 'components/layout/Header/Menu'

interface MobileProps {
  fields: GatsbyTypes.WpComponent
  isSticky?: boolean
  setIsScrollable: Function
}

const Mobile = ({ fields, setIsScrollable }: MobileProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  return (
    <div className="block lg:hidden">
      <button
        role="button"
        onClick={() => {
          setIsOpen(!isOpen)
          setIsScrollable(false)
        }}
        className="z-10 h-12 w-8 text-center"
        aria-label="Open menu"
      >
        <div className="mx-auto my-[5px] h-[2px] w-full bg-light" />
        <div className="mx-auto my-[5px] h-[2px] w-full bg-light" />
        <div className="mx-auto my-[5px] h-[2px] w-full bg-light" />
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center "
            initial={{ y: '-100vh' }}
            animate={{ y: '0vh' }}
            exit={{ y: '-100vh' }}
            drag="y"
            transition={{
              duration: 1,
            }}
            style={{
              background:
                'repeating-linear-gradient( 0deg, #D1D4DD, #D1D4DD 1px, #FFFFFF 2px, #EFEFEF 90px )',
            }}
          >
            <div className="relative flex h-full w-full items-center justify-center overflow-y-scroll">
              <div className="absolute top-10 left-16 z-50 h-[80px] w-[200px] rounded-lg border-4 border-gray-500">
                <div className="relative h-full w-full">
                  <div
                    className="absolute top-0 left-0 right-0 bottom-0 z-50"
                    style={{
                      boxShadow: 'inset 0 0 2000px rgba(255, 255, 255, .5)',
                      background: 'rgba( 0, 0, 0, 0.10 )',
                    }}
                  />
                  <Logo className="left absolute top-0 left-0 right-0 bottom-0 z-40" />

                  <div className="absolute top-0 left-0 right-0 bottom-0 z-30 rounded-sm bg-secondary" />
                </div>
              </div>
              <div
                className="absolute right-5 top-16 text-center"
                role="button"
                onClick={() => {
                  setIsOpen(!isOpen)
                  setIsScrollable(true)
                }}
                aria-label="Close menu"
              >
                <div className="relative ml-4 h-8 w-8 before:absolute before:h-[33px] before:w-[2px] before:rotate-45 before:bg-secondary before:[content:''] after:absolute after:h-[33px] after:w-[2px] after:-rotate-45 after:bg-secondary after:[content:'']" />
              </div>
              <ul className="!mt-[46vh] !mb-[20vh] flex flex-col items-center overflow-y-scroll pl-0 lg:w-8/12">
                {/* @ts-ignore */}
                <Menu fields={fields} />
              </ul>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default Mobile
