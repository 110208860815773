import React from 'react'
import { PageProps } from 'gatsby'

// Components
import Hero from './Hero'
import HeroUsps from './HeroUsps'
import HeroCta from './HeroCta'

export interface HeroProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Hero
  location: PageProps['location']
}

interface PostProps {
  [key: string]: React.FC<HeroProps>
}

const HeroShell = ({ fields, location }: HeroProps) => {
  const types: PostProps = {
    default: Hero,
    usps: HeroUsps,
    cta: HeroCta,
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default HeroShell
