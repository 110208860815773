import React, { type ReactElement } from 'react'

// Duuf
import FormBesjaarmer from '@ubo/form-besjaarmer'
import { useFormDuuf } from '@ubo/form-duuf'

// StaticUI Components
import ProgressBar from './StaticUI/ProgressBar'
import Specifications from './StaticUI/Specifications'
import Preview from './StaticUI/Preview'

// Step Components
import One from './Steps/One'
import Two from './Steps/Two'
import Three from './Steps/Three'
import Four from './Steps/Four'
import Five from './Steps/Five'

// Handler Components
import ResetHandler from './Handlers/ResetHandler'

// Interfaces
interface MainProps {
  currentStep: number
}

export interface StepProps {
  completed: Array<number>
  progressIndex: number
}

const Main = ({ currentStep }: MainProps) => {
  let stepComponent: ReactElement

  switch (currentStep) {
    case 1:
      stepComponent = <Two />
      break
    case 2:
      stepComponent = <Three />
      break
    case 3:
      stepComponent = <Four />
      break
    case 4:
      stepComponent = <Five />
      break
    default:
      stepComponent = <One />
  }

  return (
    <>
      {/* <FormBesjaarmer name="form-values" /> */}
      <ProgressBar />
      <div className="grid grid-cols-12 gap-x-8">
        <div className="col-span-12 lg:col-span-6">
          <div className="mb-8 flex flex-col items-center lg:mb-0">
            <Preview />
            <ResetHandler />
          </div>
        </div>
        <div className="col-span-12 lg:order-first lg:col-span-6">
          {stepComponent}
        </div>
      </div>
      <Specifications />
    </>
  )
}

export default Main
