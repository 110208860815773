import React from 'react'
import Plaatjie from '@ubo/plaatjie'
import Button from 'components/elements/Button'

// Components
import ParseContent from 'components/shared/ParseContent'
import Employee from 'components/elements/Employee'

// Interface
import { TextProps } from 'components/flex/Text/TextShell'

const TextCta = ({ fields }: TextProps) => (
  <section id="TextCta">
    <div className="container flex justify-end py-8 lg:py-16">
      <div className="lg:w-11/12">
        <div className="grid lg:grid-cols-5">
          <div className="lg:col-span-3">
            <ParseContent
              content={fields.description || ''}
              className="children-h2:font-secondary children-h2:text-xl children-h2:leading-tight children-p:mb-3 lg:pr-20"
            />
          </div>
          <div className="mt-4 max-h-[483px] bg-light px-6 py-10 shadow-lg sm:px-8 lg:col-span-2 lg:-mt-56">
            <div className="flex w-full items-end justify-between">
              <ParseContent
                content={fields.ctadescription || '#'}
                className="children-h2:pb-0 children-h2:text-4xl children-h2:leading-tight"
              />
              <Employee fields={fields.employee} />
            </div>
            <ParseContent
              content={fields.employee?.employee?.cta || ''}
              className="mt-12"
            />
            {fields.links?.map((node, index) => (
              <div className="mt-12 flex justify-center" key={index}>
                <Button
                  to={node?.link?.url || '#'}
                  className="btn-primary flex items-center"
                >
                  <Plaatjie image={node?.image} alt="" />
                  <span>{node?.link?.title}</span>
                </Button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default TextCta
