export const distribution = {
  startingPrice: 1699,
  fields: [
    {
      id: 'input_2',
      imgPathIndex: null,
      options: [
        {
          value: 'Sectionaaldeur',
        },
        {
          value: 'Sectionaaldeur Renomatic',
        },
        {
          value: 'Openslaande deuren',
        },
        {
          value: 'Kanteldeuren',
        },
        {
          value: 'Roldeuren',
        },
      ],
    },
    {
      id: 'input_3',
      imgPathIndex: null,
      options: [
        {
          value: 'LPU 67 Thermo',
        },
        {
          value: 'LPU 42 Dubbelwandig',
        },
        {
          value: 'LTE 42 Enkelwandig',
        },
        {
          value: 'LTH 42 Massief hout',
        },
      ],
    },
    {
      id: 'input_5',
      imgPathIndex: null,
      options: [
        {
          value: '2375 x 2000 mm (bxh)',
        },
        {
          value: '2375 x 2125 mm (bxh)',
        },
        {
          value: '2500 x 2000 mm (bxh)',
        },
        {
          value: '2375 x 2125 mm (bxh)',
        },
      ],
    },
    {
      id: 'input_6',
      imgPathIndex: -1,
      options: [
        {
          value: 'M-profilering',
          asset: 'm',
        },
        {
          value: 'L-profilering',
          asset: 'l',
          price: 200,
        },
      ],
    },
    {
      id: 'input_7',
      imgPathIndex: 0,
      options: [
        {
          value: 'Woodgrain',
          asset: 'woodgrain',
          profiling: ['m'],
        },
        {
          value: 'Decocolor',
          asset: 'decocolor',
          profiling: ['m', 'l'],
        },
        {
          value: 'Planar',
          asset: 'planar',
          profiling: ['l'],
        },
        {
          value: 'Duragrain Diamond',
          asset: 'duragrain diamond',
          profiling: ['l'],
          price: 200,
        },
      ],
    },
    {
      id: 'input_8',
      imgPathIndex: 1,
      options: [
        {
          value: 'Golden Oak',
          asset: 'golden-oak',
          surfaces: ['decocolor'],
        },
        {
          value: 'Dark Oak',
          asset: 'dark-oak',
          surfaces: ['decocolor'],
        },
        {
          value: 'Night Oak',
          asset: 'night-oak',
          surfaces: ['decocolor'],
        },
        {
          value: 'Verkeerswit',
          asset: 'verkeerswit',
          surfaces: ['woodgrain', 'planar'],
        },
        {
          value: 'Antracietgrijs',
          asset: 'antracietgrijs',
          surfaces: ['woodgrain', 'planar'],
        },
        {
          value: 'Terrabruin',
          asset: 'terrabruin',
          surfaces: ['woodgrain', 'planar'],
        },
        {
          value: 'Wit Aluminium',
          asset: 'wit-aluminium',
          surfaces: ['planar'],
        },
        {
          value: 'Blank Aluminium',
          asset: 'blank-aluminium',
          surfaces: ['woodgrain'],
        },
        {
          value: 'Grijs Aluminium',
          asset: 'grijs-aluminium',
          surfaces: ['woodgrain', 'planar'],
        },
        {
          value: 'Antraciet Metallic',
          asset: 'antraciet-metallic',
          surfaces: ['woodgrain', 'planar'],
        },
        {
          value: 'Diamond Black',
          asset: 'diamond-black',
          surfaces: ['duragrain diamond'],
        },
        {
          value: 'Diamond Antraciet',
          asset: 'diamond-antraciet',
          surfaces: ['duragrain diamond'],
        },
        {
          value: 'Diamond Basalt',
          asset: 'diamond-basalt',
          surfaces: ['duragrain diamond'],
        },
        {
          value: 'Diamond Stone',
          asset: 'diamond-stone',
          surfaces: ['duragrain diamond'],
        },
        {
          value: 'Diamond Grey',
          asset: 'diamond-grey',
          surfaces: ['duragrain diamond'],
        },
        {
          value: 'Diamond Brown',
          asset: 'diamond-brown',
          surfaces: ['duragrain diamond'],
        },
        {
          value: 'Diamond Red',
          asset: 'diamond-red',
          surfaces: ['duragrain diamond'],
        },
        {
          value: 'Diamond Green',
          asset: 'diamond-green',
          surfaces: ['duragrain diamond'],
        },
      ],
    },
  ],
}
