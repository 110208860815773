import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'
import Button from 'components/elements/Button'

interface BlogGridPostProps {
  key: string
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: GatsbyTypes.WpPost
}

const BlogGridPost = ({ key, node, blogFields }: BlogGridPostProps) => (
  <div
    key={key}
    className="flex h-full flex-col justify-between rounded-2xl bg-slate-100 p-5"
  >
    <div>
      <h2 className="text-xl font-bold">{node.title}</h2>
      <ParseContent
        className="z-10 h-[75px] max-w-full overflow-hidden line-clamp-3"
        content={node.recap?.excerpt || ''}
      />
    </div>
    <div className="mt-8">
      <Button className="btn-primary" to={node.uri || '/'}>
        {blogFields.readmoretext}
      </Button>
    </div>
  </div>
)

export default BlogGridPost
