import React, { useState } from 'react'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'

// Interface
import { HeroProps } from './HeroShell'

const HeroUsps = ({ fields }: HeroProps) => {
  return (
    <section id="HeroUsps" className="relative mb-20 bg-secondary text-light">
      <div className="container py-20">
        <div className="mx-auto xl:w-3/4">
          <ParseContent content={fields.description || ''} />
          <div className="my-6 grid grid-cols-12 gap-x-8">
            {fields.usps?.map((usp, index) => (
              <div
                className="col-span-12 flex items-center sm:col-span-4"
                key={index}
              >
                <Plaatjie
                  image={usp?.icon}
                  alt=""
                  className="mr-5 h-[25px] w-[25px] md:h-[50px] md:w-[50px]"
                />
                <span className="font-primary leading-tight md:text-xl lg:text-3xl">
                  {usp?.link?.title}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroUsps
