// Functions
import { distribution } from '../dist'

export const generateActiveSurfaces = (fieldIndex: number, values: any) => {
  const fieldId = `input_${fieldIndex}`

  const selected = distribution.fields.find((field) => field.id === fieldId)

  const option = selected?.options.find(
    (option: any) => option.value === values[fieldId]
  )

  if (!option) {
    return
  }

  const field = distribution.fields.find((edge) => edge.id === 'input_7')

  if (!field) {
    return
  }

  const surfaces = field.options.filter((edge: any) =>
    // @ts-ignore
    edge.profiling.includes(option.asset)
  )

  return surfaces
}
