import React from 'react'

// Libraries
import Select from 'react-select'

// Formik
import { useFormikContext, FormikContextType } from 'formik'

// Form
import { FormDuufLabel, FormDuufError } from '@ubo/form-duuf'

// Elements
import { prepareFormDuufField } from '@ubo/form-duuf'

interface SelectFieldProps {
  id: number
  onClick?: Function
}

const SelectField: React.FC<SelectFieldProps> = ({ id, onClick }) => {
  const { handleChange, values }: FormikContextType<{}> = useFormikContext<{}>()
  const field = prepareFormDuufField(id)
  const formik: FormikContextType<{}> = useFormikContext<{}>()

  const choices = JSON.parse(field.data.choices)
  const options = choices.map((choice: any) => ({
    value: choice.value,
    label: choice.text,
  }))

  if (id === 2) {
    field.data.isRequired = true
  }

  return (
    <div className={`form-duuf-group form-duuf-group-${field.data.type}`}>
      <FormDuufLabel fieldId={field.fieldId} data={field.data} />
      <FormDuufError
        fieldId={field.fieldId}
        data={field.data}
        hasError={!!field.hasError}
        formik={formik}
      />
      <Select
        onChange={(event) => {
          handleChange({
            target: {
              name: field.fieldId,
              value: event.value,
            },
          })
        }}
        placeholder={field.data.placeholder}
        options={options}
        value={
          options.find(
            // @ts-ignore
            ({ value }: any) => value === values[field.fieldId]
          ) || null
        }
        classNamePrefix="form-duuf-select"
        className={field.data.cssClass || ''}
      />
    </div>
  )
}

export default SelectField
