import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BlogBink, {
  useBlogBink,
  BlogBinkButton,
  BlogBinkFilter,
  BlogBinkPosts,
} from '@ubo/blog-bink'

// Components
import Button from 'components/elements/Button'
import GaragedoorPost from 'components/elements/Posts/GaragedoorPost'
import ParseContent from 'components/shared/ParseContent'

// Interface
import { GaragedoorsProps } from 'components/flex/Garagedoor/GaragedoorShell'
import { filter, has } from 'lodash'

const Overview = ({ fields }: GaragedoorsProps) => {
  const { allWpGaragedoor, allWpDoorKind } =
    useStaticQuery<GatsbyTypes.garagedoorOverviewQueryQuery>(graphql`
      query garagedoorOverviewQuery {
        allWpGaragedoor(sort: { order: ASC, fields: date }) {
          edges {
            node {
              ...generalGaragedoorFragment
            }
          }
        }
        allWpDoorKind(filter: { name: { nin: "Uncategorized" } }) {
          edges {
            node {
              databaseId
              id
              name
              wpParent {
                node {
                  name
                }
              }
            }
          }
        }
      }
    `)

  const { sorting } = fields

  const garagedoors: unknown = allWpGaragedoor.edges.filter(
    ({ node }) => node.recap?.sorting === sorting
  )

  const categories = allWpDoorKind.edges.filter(
    ({ node }) => node.wpParent?.node?.name?.toLowerCase() === sorting
  )

  return (
    <section id="GaragedoorOverview" className="bg-greyLight py-20">
      <ParseContent content={fields.description || ''} />
      <BlogBink
        posts={garagedoors as BlogBinkPosts}
        categories={categories}
        categoryName="doorVariety"
        id="garagedoor-overview"
        limit={Infinity}
      >
        <div className="py-5">
          <div className="mt-6 mb-16">
            <BlogFilters />
          </div>
          <BlogGrid fields={fields} sorting={sorting || 'private'} />
        </div>
      </BlogBink>
    </section>
  )
}

interface BlogFiltersProps {}

interface BlogFilterProps {
  node: {
    id: string
    name: string
  }
}

const BlogFilters = ({}: BlogFiltersProps) => {
  const blogBink = useBlogBink()

  const hash = typeof window !== 'undefined' ? window.location.hash : ''

  useEffect(() => {
    if (hash === '#garage') {
      return
    }
    blogBink.reset()
    const hashFilter = window.location.hash

    if (hashFilter !== '') {
      const category = blogBink.categories.find((edge: BlogFilterProps) => {
        const noInitialDashes = edge.node.name.replace('-', '')
        const filterName = noInitialDashes.replace(/\s+/g, '-').toLowerCase()

        return filterName === hashFilter.replace('#', '')
      })

      let garageFilters: Array<string> = []

      if (hashFilter === '#garage') {
        blogBink.categories.forEach((edge: any) => {
          if (edge.node.name.toLowerCase() !== 'aandrijvingen') {
            blogBink.selectedFilters.push(edge.node.id)
          }
        })

        console.log(blogBink.selectedFilters)
      } else {
        if (category) {
          if (!blogBink.selectedFilters.includes(category.node.id)) {
            blogBink.setSelectedFilters(category.node.id)
          }
        }
      }
    }
  }, [hash])

  return (
    <div className="container flex justify-end">
      <div className="lg:w-11/12">
        {blogBink.categories.map((category: BlogFilterProps) => {
          return (
            <BlogBinkFilter
              key={category.node.id}
              id={category.node.id}
              className={`relative mr-3 mb-4 bg-light py-1 px-2 hover:bg-secondary hover:text-light sm:px-6 sm:py-4 ${
                blogBink.isSelected(category.node.id) && `font-bold`
              }`}
            >
              <span>{category.node.name}</span>
              {blogBink.isSelected(category.node.id) && (
                <div className="absolute -right-2 -top-2 bg-secondary px-2 text-light">
                  x
                </div>
              )}
            </BlogBinkFilter>
          )
        })}
      </div>
    </div>
  )
}

interface BlogGridProps {
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Garagedoorz
  sorting: string
}

const BlogGrid = ({ fields, sorting }: BlogGridProps) => {
  const blogBink = useBlogBink()

  return (
    <div className="container flex justify-end">
      <div className="lg:w-11/12">
        <div className="grid gap-4 lg:grid-cols-3">
          {blogBink.posts.length > 0 &&
            blogBink.posts.map((post, index) => {
              const { node }: any = post

              return (
                <div
                  key={`post-${index}-${post.node.id}`}
                  className="lg:col-span-1"
                >
                  <GaragedoorPost
                    key={post.node.id}
                    node={node}
                    blogFields={fields}
                  />
                </div>
              )
            })}
          {blogBink.posts.length < 1 && (
            <div className="lg:col-span-2">
              <h2 className="pr-20 text-3xl">
                Er kunnen geen projecten gevonden worden voor deze categorie,
                probeer een andere categorie of probeer het later nog eens.{' '}
              </h2>
            </div>
          )}
          <div className="lg:col-span-1">
            <GarageDoorCta sorting={sorting} />
          </div>
        </div>

        {blogBink.showMoreButton && (
          <div className="mt-5 flex justify-center text-center">
            <Button
              className="rounded-lg bg-secondary text-light hover:bg-primary hover:transition-all"
              to=""
              isCustom
            >
              <BlogBinkButton className="px-6 py-2 font-bold">
                {fields.loadmoretext}
              </BlogBinkButton>
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

interface CtaProps {
  sorting: string
}

const GarageDoorCta = ({ sorting }: CtaProps) => {
  let content: string = ''
  let cta: string = ''
  let to: string = ''

  if (sorting === 'particulier') {
    content = 'Op zoek naar een deur voor industrie?'
    cta = 'Bekijk ons zakelijke aanbod'
    to = '/voor-industrie/'
  }

  if (sorting === 'zakelijk') {
    content = 'Op zoek naar een deur voor thuis?'
    cta = 'Bekijk ons particuliere aanbod'
    to = '/voor-woning/'
  }

  return (
    <div className="flex h-full flex-col justify-between bg-secondary px-10 pt-20 pb-8 text-light lg:min-h-[601px]">
      <h2 className="font-primary text-2xl font-bold leading-tight md:text-6xl">
        {content}
      </h2>
      <Button
        to={to}
        className="btn-primary-outline mt-8 text-center md:mx-8 md:mt-0"
      >
        {cta}
      </Button>
    </div>
  )
}

export default Overview
