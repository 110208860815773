import React from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'

// Hooks
import usePage from 'hooks/usePage'

// Images
import Chevron from 'img/chevron.inline.svg'
import { motion } from 'framer-motion'

interface MultiLanguageProps {
  primaryLocale: string
  type: 'dynamic' | 'select'
  display: {
    select: 'flags' | 'native' | 'locale' | 'flags-native' | 'flags-locale'
    switch: 'flags' | 'native' | 'locale' | 'flags-native' | 'flags-locale'
  }
  isOpen: boolean
}

// HOW TO ACTIVATE
// 1. Activate all WordPress Plugins in Plugin Group "Multilanguage"
// 2. Set "multilanguage" to true in gatsby-config
// 3. Open fragments in graphql folder and uncomment all multilanguage queries
// 4. yarn clean && yarn start

// HOW TO USE CUSTOM FLAGS
// 1. Out of the box, this component supports custom native flag icons
// 2a. Upload them in Wordpress under WPML > Languages > Edit languages > Custom flag
// 2b. The name of the file is required to be "<code>.svg"
// 2c. You can check what the code is under "code" in the Edit languages screen
// 2d. Example: To use 🇬🇧 : upload it as "en.svg"

const MultiLanguage: React.FC<MultiLanguageProps> = ({
  display,
  primaryLocale,
  type,
  isOpen,
}) => {
  // @ts-ignore
  const { site } = useStaticQuery<GatsbyTypes.multilanguageQueryQuery>(graphql`
    query multilanguageQuery {
      site {
        siteMetadata {
          siteWpUrl
        }
      }
    }
  `)

  const page = usePage()

  if (!page.languages || page.languages.length === 0) {
    return null
  }

  // @ts-ignore
  const { siteWpUrl } = site?.siteMetadata
  const { locale } = page.locale
  const { translations } = page
  const { languages } = page

  const navigateLocale = (goTo: string): void => {
    const translation = translations.find(({ locale }: any) => locale === goTo)

    if (translation) {
      let { href } = translation
      if (href.indexOf('/sample-page/') !== -1) {
        href = `/${href.split('/')[1]}`
      }
      if (goTo === `/${goTo.split('_')[0]}/home/`) {
        href = `/${href.split('/')[1]}/`
      }
      if (href === `${siteWpUrl}`) {
        href = '/'
      }
      navigate(href)
    }
  }

  return (
    <>
      {type === 'select' ||
        (type === 'dynamic' && languages.length > 2 && (
          <Select
            primaryLocale={primaryLocale}
            type={type}
            display={display}
            isOpen={isOpen}
            siteWpUrl={siteWpUrl}
            locale={locale}
            translations={translations}
            languages={languages}
            navigateLocale={navigateLocale}
          />
        ))}
      {type === 'dynamic' && languages?.length < 3 && (
        <Switch
          siteWpUrl={siteWpUrl}
          primaryLocale={primaryLocale}
          translations={translations}
          languages={languages}
          type={type}
          display={display}
          locale={locale}
          navigateLocale={navigateLocale}
          isOpen={isOpen}
        />
      )}
    </>
  )
}

interface SelectProps extends MultiLanguageProps {
  siteWpUrl: string
  locale: string
  translations: []
  languages: Array<{
    native_name: string
    default_locale: string
  }>
  navigateLocale: (goTo: string) => void
}

const Select: React.FC<SelectProps> = ({
  display,
  isOpen,
  siteWpUrl,
  locale,
  translations,
  languages,
  navigateLocale,
}) => {
  const splitLocale = locale.split('_')[0]

  const currentLocale = languages.find(
    (node: any) => node.default_locale === locale
  )

  return (
    <>
      <div className="locale-current">
        {display.select.includes('flags') && (
          <img
            className="locale-flag-active"
            src={`${siteWpUrl}/wp-content/uploads/flags/${splitLocale.toLowerCase()}.svg`}
          />
        )}
        {display.select.includes('native') && (
          <span>{currentLocale?.native_name}</span>
        )}
        {display.select.includes('locale') && (
          <span>{splitLocale.toUpperCase()}</span>
        )}
        <Chevron className="locale-chevron" />
      </div>
      {isOpen && (
        <div className="locale-menu">
          {translations.map((translation: any) => {
            const language = languages.filter(
              (node: any) => node.default_locale === translation.locale
            )

            return (
              <button
                className="locale-menu-item"
                onClick={() => {
                  navigateLocale(translation.locale)
                }}
              >
                {display.select.includes('flags') && (
                  <img
                    className="locale-flag"
                    src={`${siteWpUrl}/wp-content/uploads/flags/${
                      translation.locale.split('_')[0]
                    }.svg`}
                  />
                )}

                {display.select.includes('native') && (
                  <span>{language[0].native_name}</span>
                )}

                {display.select.includes('locale') && (
                  <span>
                    {language[0].default_locale.split('_')[0].toUpperCase()}
                  </span>
                )}
              </button>
            )
          })}
        </div>
      )}
    </>
  )
}

interface SwitchProps extends MultiLanguageProps {
  siteWpUrl: string
  navigateLocale: (goTo: string) => void
  translations: []
  languages: Array<{
    native_name: string
    default_locale: string
  }>
  locale: string
}

const Switch: React.FC<SwitchProps> = ({
  siteWpUrl,
  primaryLocale,
  display,
  navigateLocale,
  translations,
  locale,
  languages,
}) => {
  // @ts-ignore
  const translation = translations[0]?.locale

  const primaryNativeName = languages.find(
    (node) => node.default_locale === primaryLocale
  )?.native_name

  const secondaryNativeName = languages.find(
    (node) => node.default_locale === translation
  )?.native_name

  const secondaryLocale = languages.find(
    (node) => node.default_locale === translation
  )?.default_locale

  let splitSecondaryLocale: string | undefined
  if (locale !== primaryLocale) {
    // @ts-ignore
    splitSecondaryLocale = languages
      .find((node) => node.default_locale === locale)
      .default_locale.split('_')[0]
  } else {
    splitSecondaryLocale = secondaryLocale?.split('_')[0]
  }

  const splitPrimaryLocale = primaryLocale.split('_')[0]

  return (
    <>
      <div className="locale-switch-menu">
        {display.switch.includes('native') && <span>{primaryNativeName}</span>}
        {display.switch.includes('locale') && (
          <span>{splitPrimaryLocale.toUpperCase()}</span>
        )}
        {display.switch.includes('flags') && (
          <img
            className="locale-flag-primary"
            src={`${siteWpUrl}/wp-content/uploads/flags/${splitPrimaryLocale.toLowerCase()}.svg`}
          />
        )}
        <motion.div
          className="locale-switch-wrapper"
          onClick={() => navigateLocale(translation)}
          animate={
            translation !== primaryLocale
              ? {
                  justifyContent: 'flex-start',
                }
              : {
                  justifyContent: 'flex-end',
                }
          }
        >
          <div className="locale-switch" />
        </motion.div>
        {display.switch.includes('flags') && (
          <img
            className="locale-flag"
            src={`${siteWpUrl}/wp-content/uploads/flags/${splitSecondaryLocale?.toLowerCase()}.svg`}
          />
        )}
        {display.switch.includes('native') && (
          <span>{secondaryNativeName}</span>
        )}
        {display.switch.includes('locale') && (
          <span>{splitSecondaryLocale?.toUpperCase()}</span>
        )}
      </div>
    </>
  )
}

export default MultiLanguage
