// Functions
import { distribution } from '../dist'

export const generateActiveColors = (fieldIndex: number, values: any) => {
  const selected = distribution.fields.find(
    (field) => field.id === `input_${fieldIndex}`
  )

  const colors = selected?.options.filter((option: any) =>
    option.surfaces.includes(values.input_7.toLowerCase())
  )

  return colors || []
}
