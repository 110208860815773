import React, { useState, useEffect } from 'react'
import { getImagePath } from '../Functions/getImagePath'

// Hooks
import { useFormDuuf } from '@ubo/form-duuf'
import { getProfilingPath } from '../Functions/getProfilingPath'
import useConfigurator from '../Hooks/useConfigurator'

const Preview = () => {
  const duuf = useFormDuuf()
  const config = useConfigurator()

  const [imagePath, setImagePath] = useState('woodgrain-verkeerswit.jpg')
  const [profilingPath, setProfilingPath] = useState('m.png')

  useEffect(() => {
    setImagePath(getImagePath(duuf.formik.values))
    setProfilingPath(getProfilingPath(duuf.formik.values))
  }, [duuf.formik.values])

  return (
    <div className="mt-24 w-[90%]">
      <div className="relative">
        <div>
          <img
            src={`${config.startUrl}/img/preview/profiling/${profilingPath}`}
            alt="Profilering afbeelding"
            height="100%"
            width="100%"
          />
        </div>
        <div className="absolute top-0 left-0 right-0 bottom-0 -z-10 h-full w-full">
          <img
            className="h-full w-full"
            src={`${config.startUrl}/img/preview/backgrounds/${imagePath}`}
            alt=""
          />
        </div>
      </div>
    </div>
  )
}

export default Preview
