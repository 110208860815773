import React from 'react'

// Components
import StepHandler from '../Handlers/StepHandler'
import RadioFieldText from '../Fields/RadioFieldText'

// Images
import Exclamation from 'img/exclamation.inline.svg'

// Interface
import { useFormDuuf } from '@ubo/form-duuf'
import useConfigurator from '../Hooks/useConfigurator'

const Two = () => {
  const config = useConfigurator()
  const duuf = useFormDuuf()
  const enabled = !!duuf.formik.values.input_5

  return (
    <div className="mt-20">
      <h2 className="mb-12 text-2xl font-bold">
        2. Maat van de deur<span className="form-duuf-label-mandatory">*</span>
      </h2>
      <RadioFieldText id={5} />
      <div className="flex justify-start">
        <Exclamation className="mt-[6px] mr-[10px]" />
        <span>
          Staat uw maat er niet tussen? Neem dan&nbsp;
          <button
            type="button"
            className="underline"
            onClick={() => {
              duuf.formik.setFieldValue('input_5', '', false)

              config.setCurrentStep(4)
              config.setQuotation(true)
            }}
          >
            contact
          </button>
          &nbsp;met ons op voor een offerte op maat.
        </span>
      </div>
      <StepHandler step={2} enabled={enabled} />
    </div>
  )
}

export default Two
