import React from 'react'
import { PageProps } from 'gatsby'

// Components
import FlexContentHandler from 'components/shared/FlexContentHandler'

export interface FragmentProps {
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Fragment
  location: PageProps['location']
  pageContext: any
}

const Fragment = ({ fields, location, pageContext }: FragmentProps) => {
  return (
    <FlexContentHandler
      prefix="Fragment_FlexFragment"
      fields={fields.element?.flex_fragment}
      location={location}
      pageContext={pageContext}
    />
  )
}

export default Fragment
