// Functions
import { distribution } from '../dist'

export const calculatePrice = (values: any) => {
  if (values.input_2 !== 'Sectionaaldeur Renomatic') {
    return 'Op aanvraag'
  }

  let totalPrice = distribution.startingPrice

  distribution.fields.forEach((field) => {
    const value = values[field.id]

    if (!value) {
      return
    }

    const selected = field.options.find((option) => option.value === value)

    if (!selected) {
      return
    }

    // @ts-ignore
    totalPrice += selected.price || 0
  })

  return `€${totalPrice.toLocaleString('nl-NL')},-`
}
