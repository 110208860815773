import React from 'react'

const Loading: React.FC = () => (
  <div className="absolute left-0 right-0 bottom-0 top-0 z-10 flex cursor-progress bg-white bg-opacity-50">
    <div className="relative m-auto block h-[60px] w-[60px] transition-all">
      <div className="animation-spinner-base relative h-[60px] w-[60px]">
        <div className="absolute left-0 top-0 h-full w-full [animation:sk-chase-dot_2s_infinite_ease-in-out_both] [animation-delay:-1.1s] before:block before:h-1/4 before:w-1/4 before:rounded-full before:bg-primary before:[content:''] before:[animation:sk-chase-dot-before_2s_infinite_ease-in-out_both] before:[animation-delay:-1.1s]" />
        <div className="absolute left-0 top-0 h-full w-full [animation:sk-chase-dot_2s_infinite_ease-in-out_both] [animation-delay:-1.0s] before:block before:h-1/4 before:w-1/4 before:rounded-full before:bg-primary before:[content:''] before:[animation:sk-chase-dot-before_2s_infinite_ease-in-out_both] before:[animation-delay:-1.0s]" />
        <div className="absolute left-0 top-0 h-full w-full [animation:sk-chase-dot_2s_infinite_ease-in-out_both] [animation-delay:-0.9s] before:block before:h-1/4 before:w-1/4 before:rounded-full before:bg-primary before:[content:''] before:[animation:sk-chase-dot-before_2s_infinite_ease-in-out_both] before:[animation-delay:-0.9s]" />
        <div className="absolute left-0 top-0 h-full w-full [animation:sk-chase-dot_2s_infinite_ease-in-out_both] [animation-delay:-0.8s] before:block before:h-1/4 before:w-1/4 before:rounded-full before:bg-primary before:[content:''] before:[animation:sk-chase-dot-before_2s_infinite_ease-in-out_both] before:[animation-delay:-0.8s]" />
        <div className="absolute left-0 top-0 h-full w-full [animation:sk-chase-dot_2s_infinite_ease-in-out_both] [animation-delay:-0.7s] before:block before:h-1/4 before:w-1/4 before:rounded-full before:bg-primary before:[content:''] before:[animation:sk-chase-dot-before_2s_infinite_ease-in-out_both] before:[animation-delay:-0.7s]" />
        <div className="absolute left-0 top-0 h-full w-full [animation:sk-chase-dot_2s_infinite_ease-in-out_both] [animation-delay:-0.6s] before:block before:h-1/4 before:w-1/4 before:rounded-full before:bg-primary before:[content:''] before:[animation:sk-chase-dot-before_2s_infinite_ease-in-out_both] before:[animation-delay:-0.6s]" />
      </div>
    </div>
  </div>
)

export default Loading
