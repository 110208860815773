import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BlogBink, {
  useBlogBink,
  BlogBinkButton,
  BlogBinkFilter,
  BlogBinkPosts,
} from '@ubo/blog-bink'

// Components
import Button from 'components/elements/Button'
import BlogGridPost from 'components/elements/Posts/BlogGridPost'

// Interface
import { PostsProps } from 'components/flex/Posts/PostsShell'

const Blog = ({ fields }: PostsProps) => {
  const { allWpPost, allWpCategory } =
    useStaticQuery<GatsbyTypes.blogQueryQuery>(graphql`
      query blogQuery {
        allWpPost(sort: { order: ASC, fields: date }) {
          edges {
            node {
              ...generalPostFragment
            }
          }
        }
        allWpCategory(filter: { name: { nin: "Uncategorized" } }) {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `)

  const posts: unknown = allWpPost.edges

  return (
    <section id="Blog">
      <BlogBink
        posts={posts as BlogBinkPosts}
        categories={allWpCategory.edges}
        id="blog"
        limit={Number(fields.limit)}
      >
        <div className="py-5">
          <BlogFilters />
          <BlogGrid fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

interface BlogFiltersProps {}

interface BlogFilterProps {
  node: {
    id: string
    name: string
  }
}

const BlogFilters = ({}: BlogFiltersProps) => {
  const blogBink = useBlogBink()

  return (
    <div className="d-flex container">
      {blogBink.categories.map((category: BlogFilterProps) => (
        <BlogBinkFilter
          key={category.node.id}
          id={category.node.id}
          className={`mr-3 ${
            blogBink.isSelected(category.node.id) && `font-bold`
          }`}
        >
          {category.node.name}
        </BlogBinkFilter>
      ))}
    </div>
  )
}

interface BlogGridProps {
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const BlogGrid = ({ fields }: BlogGridProps) => {
  const blogBink = useBlogBink()

  return (
    <div className="container">
      <div className="grid grid-cols-3 gap-4">
        {blogBink.posts.map((post, index) => {
          const { node }: any = post

          return (
            <div key={`post-${index}-${post.node.id}`} className="col-span-1">
              <BlogGridPost
                key={post.node.id}
                node={node}
                blogFields={fields}
              />
            </div>
          )
        })}
      </div>

      {blogBink.showMoreButton && (
        <div className="mt-5 flex justify-center text-center">
          <Button
            className="rounded-lg bg-secondary text-light hover:bg-primary hover:transition-all"
            to=""
            isCustom
          >
            <BlogBinkButton className="px-6 py-2 font-bold">
              {fields.loadmoretext}
            </BlogBinkButton>
          </Button>
        </div>
      )}
    </div>
  )
}

export default Blog
