import React, { useState } from 'react'
import { motion } from 'framer-motion'
import Plaatjie from '@ubo/plaatjie'

// Components
import Button from 'components/elements/Button'

interface ProjectPostProps {
  key: string
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_Projects
  node: GatsbyTypes.WpProject
  handleLightbox: Function
  index: number
}

const ProjectPost = ({
  key,
  node,
  handleLightbox,
  index,
}: ProjectPostProps) => {
  const [isHover, setHover] = useState(false)

  return (
    <>
      <motion.div
        key={key}
        className="relative h-full"
        onHoverStart={() => setHover(true)}
        onHoverEnd={() => setHover(false)}
        animate={isHover ? { scale: 1.05 } : { scale: 1 }}
      >
        <button
          className="relative z-50 h-[225px] lg:h-[445px]"
          onClick={() => handleLightbox(index)}
        >
          <div className="pointer-events-none absolute bottom-0 z-10 w-full bg-gradient-to-b from-gradient-darkTo via-gradient-darkVia to-gradient-darkFrom text-center text-light lg:p-10">
            <h2 className="mb-10 font-primary text-xl font-bold leading-none tracking-wide lg:text-3xl">
              {node.title}
            </h2>
            <div className="btn-primary" role="button">
              Bekijk project
            </div>
          </div>
          <Plaatjie
            image={node.recap?.thumbnail}
            alt={node.title || ''}
            className="h-[225px] lg:h-[445px]"
          />
        </button>
      </motion.div>
    </>
  )
}

export default ProjectPost
