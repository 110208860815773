/* eslint-disable react/no-array-index-key */
import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'

// Components
import NavLink from 'components/shared/NavLink'
import Submenu from 'components/layout/Header/Submenu'
import Button from 'components/elements/Button'
import Plaatjie from '@ubo/plaatjie'

// Images
import Chevron from 'img/chevron.inline.svg'

interface MenuProps {
  fields: readonly GatsbyTypes.WpComponent_Header_menuHeader[]
  isSticky: boolean
}

const Menu = ({ fields, isSticky }: MenuProps) => {
  const [open, setOpen] = React.useState<number>(-1)

  return (
    <>
      {fields.map((l, index) => (
        <motion.li
          onHoverStart={() => setOpen(index)}
          onHoverEnd={() => setOpen(-1)}
          key={index}
          className={`mb-4 lg:pl-7 ${isSticky ? 'lg:mb-1' : 'mt-3'}`}
        >
          {l.highlighted?.button ? (
            <Button
              className="btn-primary hidden items-center !text-light xl:flex"
              to={l.link?.url || '#'}
            >
              <Plaatjie image={l.highlighted.icon} alt="" className="mr-3" />
              <span>{l.link?.title}</span>
            </Button>
          ) : (
            <div className="ml-1 flex flex-wrap items-center justify-center lg:justify-start">
              <NavLink
                className={`font-bold text-secondary hover:opacity-70 lg:font-light lg:text-light ${
                  l.highlighted?.icon ? 'mt-[2.4px] flex items-center' : ''
                }`}
                to={l?.link?.url || '/'}
              >
                {l.highlighted?.icon && (
                  <Plaatjie
                    image={l.highlighted.icon}
                    alt=""
                    className="mr-2 hidden lg:flex"
                  />
                )}
                <span
                  className={`${
                    l.highlighted?.icon ? 'leading-none' : ''
                  } text-lg lg:text-tiny xl:text-base`}
                >
                  {l?.link?.title}
                </span>
              </NavLink>
              {l?.submenu && (
                <div
                  onClick={
                    open === index ? () => setOpen(-1) : () => setOpen(index)
                  }
                >
                  <Chevron
                    className="mt-[10px] ml-2 transition-all children:fill-secondary lg:mt-[11px] lg:children:fill-light"
                    style={
                      open === index
                        ? {
                            transform: 'rotate(180deg)',
                          }
                        : {
                            transform: 'rotate(0deg)',
                          }
                    }
                  />
                </div>
              )}
            </div>
          )}
          <AnimatePresence>
            {l?.submenu && open === index && (
              <Submenu fields={l.submenu} isSticky={isSticky} />
            )}
          </AnimatePresence>
        </motion.li>
      ))}
    </>
  )
}

export default Menu
