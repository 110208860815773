/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import Plaatjie from '@ubo/plaatjie'
import { navigate } from 'gatsby'
import { motion } from 'framer-motion'

// Components
import NavLink from 'components/shared/NavLink'
import ParseContent from 'components/shared/ParseContent'

interface SubmenuProps {
  // eslint-disable-next-line
  fields: readonly GatsbyTypes.Maybe<GatsbyTypes.WpComponent_Header_menuHeader_submenu>[]
  isSticky: boolean
}

const Submenu = ({ fields, isSticky }: SubmenuProps) => {
  const [currentSub, setCurrentSub] = useState(0)
  const [currentIndex, setCurrentIndex] = useState(0)

  return (
    <motion.ul
      className={`relative left-0 w-full overflow-hidden lg:absolute  ${
        isSticky ? ' lg:top-[85px]' : 'lg:top-[110px]'
      }`}
      initial={{ height: 0, opacity: 0 }}
      animate={{ height: 'fit-content', opacity: 1 }}
      exit={{ height: 0, opacity: 0 }}
    >
      <div className={`${isSticky ? 'pt-5' : 'lg:pt-10'}`}>
        <div className="lg:bg-light lg:shadow-2xl">
          <div className="lg:container lg:py-3">
            <div className="lg:p-4 lg:py-1 lg:pr-2 lg:pl-3 ">
              <div className="mx-auto grid grid-cols-6 lg:w-5/6">
                <div className="col-span-2 hidden lg:flex">
                  <Plaatjie
                    image={fields![currentSub]?.sublinks![currentIndex]?.image}
                    alt=""
                    className="h-[290px] w-[287px]"
                  />
                </div>
                <div className="col-span-6 lg:col-span-4">
                  <div className="grid grid-cols-6">
                    {fields &&
                      fields.map((sub, subIndex) => {
                        return (
                          <motion.div
                            className="col-span-6 mb-4 lg:col-span-3 lg:mb-0"
                            onHoverStart={() => setCurrentSub(subIndex)}
                            key={subIndex}
                          >
                            <button
                              className="flex justify-center font-bold !text-secondary hover:underline lg:justify-start lg:text-3xl"
                              onClick={() => {
                                if (sub?.mainlink?.url?.includes('#garage')) {
                                  if (typeof localStorage !== 'undefined') {
                                    localStorage.setItem(
                                      'garagedoor-overview-filters',
                                      JSON.stringify([
                                        'dGVybToyMTU=',
                                        'dGVybToyMTg=',
                                        'dGVybToyMDk=',
                                        'dGVybToyMTI=',
                                      ])
                                    )
                                  }
                                } else {
                                  if (typeof localStorage !== 'undefined') {
                                    localStorage.setItem(
                                      'garagedoor-overview-filters',
                                      ''
                                    )
                                  }
                                }

                                window.location.href = sub?.mainlink?.url || '/'

                                setTimeout(() => {
                                  window.location.reload()
                                }, 100)
                              }}
                            >
                              {sub?.mainlink?.title}
                            </button>
                            {sub?.sublinks &&
                              sub?.sublinks.map((f, index) => (
                                <motion.li
                                  className="list-none hover:opacity-70"
                                  key={`${currentSub}-${index}`}
                                  onHoverStart={() => setCurrentIndex(index)}
                                >
                                  <NavLink
                                    className="flex-lg-row flex items-center justify-center text-secondary lg:my-4 lg:justify-start"
                                    to={f?.link?.url || '/'}
                                  >
                                    <ParseContent
                                      content={f?.link?.title || ''}
                                      className="text-lg"
                                    />
                                    {f?.sale && (
                                      <div className="ml-2 flex items-center bg-primary p-1 text-white">
                                        <span className="text-sm">ACTIE</span>
                                      </div>
                                    )}
                                  </NavLink>
                                </motion.li>
                              ))}
                          </motion.div>
                        )
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.ul>
  )
}

export default Submenu
