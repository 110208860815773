import { distribution } from '../dist'

export const getProfilingPath = (values: any) => {
  let path: Array<{ index: number; asset: string }> = []

  Object.keys(values).forEach((id) => {
    const field = distribution.fields.find((field) => field.id === id)

    if (
      !field ||
      typeof field.imgPathIndex !== `number` ||
      !field.options ||
      field.imgPathIndex !== -1
    ) {
      return
    }

    if (!field.options) {
      return
    }

    // @ts-ignore
    const selected = field.options?.find(
      (option: any) => option.value === values[id]
    )

    if (!selected) {
      return
    }

    path.push({
      index: field.imgPathIndex,
      // @ts-ignore
      asset: selected.asset,
    })
  })

  path = path.sort((node) => node.index)

  const imgPath = `${path
    .map((node) => node.asset)
    .join('-')
    .replace(/\s+/g, '-')}`

  if (imgPath === '') {
    return 'm.png'
  }

  return `${imgPath}.png`
}
