import React from 'react'
import Plaatjie from '@ubo/plaatjie'

// Interface
import { UspsProps } from 'components/flex/Usps/UspsShell'

const Usps = ({ fields }: UspsProps) => (
  <section id="Usps" className="mb-10 bg-secondary text-light lg:mb-28">
    <div className="container flex justify-center py-5">
      <div className="sm:w-5/6">
        <div className="grid grid-cols-12 gap-4">
          {fields.usps?.map((usp, index) => (
            <div
              className="col-span-12 flex items-start sm:col-span-4"
              key={index}
            >
              <Plaatjie
                image={usp?.icon}
                alt=""
                className="mt-1 mr-5 h-[25px] w-[25px] md:h-[50px] md:w-[50px]"
              />
              <span className="font-primary leading-tight md:text-xl lg:text-3xl">
                {usp?.link?.title}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  </section>
)

export default Usps
