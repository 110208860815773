import React from 'react'
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

// Hooks
import usePage from 'hooks/usePage'

// Interface
import { GaragedoorsProps } from '../GaragedoorShell'

const DoorTypes = ({ fields }: GaragedoorsProps) => {
  const page = usePage()

  // @ts-ignore
  const doorTypes = page.doorTypes.nodes

  return (
    <section id="DoorTypes" className="bg-greyLight py-20">
      <div className="container">
        <ParseContent content={fields.description || ''} />
        <div className="grid grid-cols-4 gap-x-5">
          {doorTypes.map((doorType: any, index: number) => (
            <div
              className="relative col-span-2 mb-8 md:col-span-1 lg:mb-0"
              key={index}
            >
              <div>
                <Plaatjie
                  image={doorType.recap.image}
                  alt=""
                  className="h-full w-full children-img:!object-contain"
                />
                <div className="bg-light py-6 px-2 sm:px-7">
                  <h2 className="font-bold leading-5 sm:text-2xl">
                    {doorType.name}
                  </h2>
                  <span>{doorType.description}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default DoorTypes
