import React from 'react'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

interface CrumbProps {
  pageContext?: any
  label?: string
  location?: any
}

const Crumb = ({ pageContext, label, location }: CrumbProps) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  crumbs.forEach((element: any) => {
    // eslint-disable-next-line no-param-reassign
    element.crumbLabel = element.crumbLabel.replace(/-/g, ' ')
  })

  return (
    <div className="crumb">
      <Breadcrumb
        crumbs={crumbs}
        crumbSeparator=" > "
        crumbLabel={label}
        location={location}
      />
    </div>
  )
}

export default Crumb
