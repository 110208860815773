import React, { useState } from 'react'
import { motion } from 'framer-motion'

// Images
import Reset from 'img/reset.inline.svg'

// Hooks
import { useFormDuuf } from '@ubo/form-duuf'
import useConfigurator from '../Hooks/useConfigurator'

interface ResetHandlerProps {
  setCurrentStep: Function
  setStepsDone: Function
}

const ResetHandler = () => {
  const [resetHovered, setResetHovered] = useState(false)
  const config = useConfigurator()
  const duuf = useFormDuuf()

  const handleReset = () => {
    duuf.reset()
    config.reset()
  }

  return (
    <motion.button
      type="button"
      className="flex items-center"
      onHoverStart={() => setResetHovered(true)}
      onHoverEnd={() => setResetHovered(false)}
      onClick={() => handleReset()}
    >
      <motion.div
        variants={{
          initial: {
            rotate: 0,
          },
          hovered: {
            rotate: 180,
          },
        }}
        animate={resetHovered ? 'hovered' : 'initial'}
      >
        <Reset />
      </motion.div>
      <span className="ml-4 mb-1 text-base hover:text-primary">
        Reset configuratie
      </span>
    </motion.button>
  )
}

export default ResetHandler
