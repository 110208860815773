import React from 'react'
import Plaatjie from '@ubo/plaatjie'

interface EmployeeProps {
  fields: GatsbyTypes.Maybe<GatsbyTypes.WpEmployee>
  showInfo?: boolean
}

const Employee = ({ fields, showInfo }: EmployeeProps) => (
  <div id="Employee">
    <div className="flex items-center gap-12">
      {showInfo && (
        <div className="relative bg-secondary p-3 font-bold text-light sm:py-4 sm:px-8">
          {fields?.title}
          <div>
            <a
              href={fields?.employee?.phone?.url}
              className="hover:text-primary"
            >
              {fields?.employee?.phone?.title}
            </a>
            <div className="absolute left-full top-1/2 -mt-6 inline-block w-8 overflow-hidden">
              <div className="h-12 origin-top-left rotate-45 transform bg-dark"></div>
            </div>
          </div>
        </div>
      )}

      <div>
        <Plaatjie
          className="w-[90px] rounded-full sm:w-[155px]"
          image={fields?.employee?.image}
          alt=""
        />
      </div>
    </div>
  </div>
)

export default Employee
