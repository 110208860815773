import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'

// Hooks
import useConfigurator from '../Hooks/useConfigurator'

// Images
import Complete from 'img/complete.inline.svg'

const ProgressBar = () => {
  const config = useConfigurator()

  let steps = []

  if (!config.quotation) {
    steps = ['Soort deur', 'Maat', 'Motief en oppervlak', 'Kleur', 'Gegevens']
  } else {
    steps = ['Soort deur', 'Gegevens']
  }

  return (
    <div
      className={`grid w-full ${
        !config.quotation ? 'grid-cols-5' : 'grid-cols-2'
      }`}
    >
      {steps.map((step, index) => {
        const stepNumber = index + 1
        const progress = config.currentStep === index
        const completed =
          config.stepsCompleted.includes(stepNumber) ||
          (stepNumber === 1 && config.quotation && config.currentStep > 1)

        return (
          <button
            type="button"
            disabled={!completed}
            key={index}
            className="relative col-span-1 flex flex-col items-center "
            onClick={() => config.setCurrentStep(index)}
          >
            <AnimatePresence>
              {completed && (
                <motion.div
                  className="absolute -right-[4px] -top-[6px] z-10"
                  intial={{ opacity: 0, scale: 0.6 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.6 }}
                >
                  <Complete className="hidden h-[22px] w-[22px] sm:block" />
                </motion.div>
              )}
            </AnimatePresence>

            <div
              key={index}
              className={` h-[10px] w-full ${
                progress ? 'bg-gray-400' : 'bg-gray-200'
              }  ${
                progress || completed
                  ? '!bg-secondary hover:!bg-primary'
                  : 'bg-gray-200'
              }`}
            />
            <span
              className={`mt-2 text-xs lg:text-sm ${
                progress || completed ? 'opacity-100' : 'opacity-25'
              }`}
            >
              {step}
            </span>
          </button>
        )
      })}
    </div>
  )
}

export default ProgressBar
