import React from 'react'

// Components
import RadioFieldImage from '../Fields/RadioFieldImage'
import StepHandler from '../Handlers/StepHandler'

// Duuf
import SelectField from '../Fields/SelectField'

// Interface
import { useFormDuuf } from '@ubo/form-duuf'
import useConfigurator from '../Hooks/useConfigurator'

const One = () => {
  const duuf = useFormDuuf()
  const config = useConfigurator()

  React.useEffect(() => {
    duuf.formik.setErrors({})
  }, [])

  React.useEffect(() => {
    if (duuf.formik.values.input_1 === 'Voor thuis') {
      duuf.formik.setFieldValue('input_22', '', false)
    }

    if (duuf.formik.values.input_1 !== 'Voor thuis') {
      duuf.formik.setFieldValue('input_2', '', false)
    }

    if (duuf.formik.values.input_1 !== 'Voor thuis' && !config.quotation) {
      config.setQuotation(true)
    }
  }, [duuf.formik.values.input_1])

  React.useEffect(() => {
    const isQuotation =
      duuf.formik.values.input_2 === 'Sectionaaldeur Renomatic'

    if (duuf.formik.values.input_2) {
      if (
        duuf.formik.values.input_2 !== 'Sectionaaldeur' &&
        duuf.formik.values.input_3
      ) {
        duuf.formik.setFieldValue('input_3', '', false)
      }

      if (isQuotation && config.quotation) {
        config.setQuotation(false)
        config.setStepsCompleted()
      } else if (
        duuf.formik.values.input_2 &&
        !isQuotation &&
        !config.quotation
      ) {
        config.setQuotation(true)
        config.setStepsCompleted()
      }
    }
  }, [duuf.formik.values.input_2, duuf.formik.values.input_3])

  const enabled = !!(
    duuf.formik.values.input_1 &&
    (duuf.formik.values.input_2 || duuf.formik.values.input_22)
  )

  return (
    <div className="mt-20">
      <h2 className="mb-6 text-2xl font-bold">
        1. Soort deur<span className="form-duuf-label-mandatory">*</span>
      </h2>
      <SelectField id={1} />
      {duuf.formik.values.input_1 && (
        <>
          {duuf.formik.values.input_1 === 'Voor thuis' ? (
            <SelectField id={2} />
          ) : (
            <SelectField id={22} />
          )}
        </>
      )}
      {duuf.formik.values.input_2 &&
        duuf.formik.values.input_2 === 'Sectionaaldeur' && (
          <RadioFieldImage id={3} type="rectangle" imgFolder="doortype" />
        )}
      <StepHandler step={1} enabled={enabled} />
    </div>
  )
}

export default One
