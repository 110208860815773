import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Swiper, SwiperSlide } from 'swiper/react'
import SimpleReactLightbox, {
  SRLWrapper,
  useLightbox,
} from 'simple-react-lightbox'
import { Navigation, Pagination } from 'swiper'
import BlogBink, { useBlogBink, BlogBinkPosts } from '@ubo/blog-bink'

import Arrow from 'img/arrowswiper.inline.svg'

// CSS
import 'swiper/css'
import 'swiper/css/pagination'

// Components
import ParseContent from 'components/shared/ParseContent'
import ProjectPost from 'components/elements/ProjectPost'

// Interface
import { ProjectsProps } from './ProjectsShell'
import Plaatjie from '@ubo/plaatjie'

const Overview = ({ fields }: ProjectsProps) => {
  const {
    allWpProject,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.overviewProjectsQueryQuery>(graphql`
    query overviewProjectsQuery {
      allWpProject(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalProjectFragment
          }
        }
      }
    }
  `)

  const projects: unknown = allWpProject.edges

  return (
    <section id="Overview">
      <BlogBink
        posts={projects as BlogBinkPosts}
        id="overview-projects"
        limit={Infinity}
      >
        <div className="py-5">
          <BlogGrid fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

const BlogGrid = ({ fields }: ProjectsProps) => {
  const blogBink = useBlogBink()
  const [currentPostNode, setCurrentPostNode] = useState(0)
  const [currentImages, setCurrentImages] = useState([])

  const { openLightbox } = useLightbox()

  const handleLightbox = (index: number) => {
    setCurrentPostNode(index)
    openLightbox(index)
  }

  useEffect(() => {
    setCurrentImages(
      blogBink.posts[currentPostNode].node.recap.gallery?.map((image: any) => ({
        src: image?.localFile?.childImageSharp.gatsbyImageData.images.fallback
          .src,
      }))
    )
  }, [currentPostNode])

  return (
    <>
      <div className="relative mt-6 lg:mb-20">
        <div className="container flex justify-end">
          <div className="w-11/12">
            <ParseContent content={fields.description || ''} className="mb-4" />
          </div>
        </div>
        <div
          className="ml-auto max-w-[2000px] mg:container
        "
        >
          <SimpleReactLightbox>
            <SRLWrapper elements={currentImages}>
              <Swiper
                className="relative py-5 pr-[10px] lg:py-10"
                breakpoints={{
                  2000: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                  },
                  992: {
                    slidesPerView: 4.25,
                    spaceBetween: 16,
                  },
                  768: {
                    slidesPerView: 3.75,
                    spaceBetween: 16,
                  },
                  370: {
                    slidesPerView: 2.25,
                    spaceBetween: 16,
                  },
                  0: {
                    slidesPerView: 1.25,
                    spaceBetween: 12,
                  },
                }}
                navigation={{
                  prevEl: '.custom-swiper-button-prev',
                  nextEl: '.custom-swiper-button-next',
                }}
                modules={[Pagination, Navigation]}
              >
                {blogBink.posts.map((post, index) => {
                  const { node }: any = post

                  return (
                    <SwiperSlide
                      className="h-[225px] lg:h-full"
                      key={`post-${index}-${post.node.id}`}
                    >
                      <ProjectPost
                        key={post.node.id}
                        node={node}
                        blogFields={fields}
                        handleLightbox={handleLightbox}
                        index={index}
                      />
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </SRLWrapper>
          </SimpleReactLightbox>
        </div>
        <div className="container flex justify-end">
          <div
            className="custom-swiper-button-prev me-3 rotate-180"
            role="button"
          >
            <Arrow />
          </div>
          <div className="custom-swiper-button-next" role="button">
            <Arrow />
          </div>
        </div>
      </div>
    </>
  )
}

export default Overview
