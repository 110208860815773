/* eslint-disable no-underscore-dangle */
import React from 'react'
import { PageProps } from 'gatsby'

// Shells import
import BannerShell from 'components/flex/Banner/BannerShell'
import FormShell from 'components/flex/Form/FormShell'
import TextShell from 'components/flex/Text/TextShell'
import PostsShell from 'components/flex/Posts/PostsShell'
import ComponentShell from 'components/flex/Component/ComponentShell'
import Fragment from 'components/flex/Fragment'
import HeroShell from 'components/flex/Hero/HeroShell'
import UspsShell from 'components/flex/Usps/UspsShell'
import CtaShell from 'components/flex/Cta/CtaShell'
import ProjectsShell from 'components/flex/Projects/ProjectsShell'
import GaragedoorsShell from 'components/flex/Garagedoor/GaragedoorShell'
import Gallery from 'components/flex/Misc/Gallery'
import Configurator from 'components/flex/Misc/Configurator'

interface FlexContentProps {
  prefix?: string
  fields: GatsbyTypes.Maybe<GatsbyTypes.WpFragment_FlexFragment>
  location: PageProps['location']
  pageContext: PageProps['pageContext']
}

interface FlexerProps {
  [key: string]: React.FC<any>
}

interface ComponentProps {
  fields: GatsbyTypes.Maybe<GatsbyTypes.WpFragment_FlexFragment_Flex>
  location: PageProps['location']
  pageContext?: PageProps['pageContext']
}

const FlexContentHandler: React.FC<FlexContentProps> = ({
  prefix = 'page_Flexcontent',
  fields,
  location,
  pageContext,
}) => {
  if (!fields) {
    return null
  }

  const flexers: FlexerProps = {
    [`${prefix}_Flex_Banner`]: BannerShell,
    [`${prefix}_Flex_Form`]: FormShell,
    [`${prefix}_Flex_Text`]: TextShell,
    [`${prefix}_Flex_Hero`]: HeroShell,
    [`${prefix}_Flex_Usps`]: UspsShell,
    [`${prefix}_Flex_Cta`]: CtaShell,
    [`${prefix}_Flex_PostTypes`]: PostsShell,
    [`${prefix}_Flex_Projects`]: ProjectsShell,
    [`${prefix}_Flex_Garagedoorz`]: GaragedoorsShell,
    [`${prefix}_Flex_Gallery`]: Gallery,
    [`${prefix}_Flex_Configurator`]: Configurator,
    [`${prefix}_Flex_Components`]: ComponentShell,
    [`${prefix}_Flex_Fragment`]: Fragment,
  }

  if (!fields.flex) {
    return null
  }

  return (
    <>
      {fields.flex.map((section, index) => {
        if (!section?.fieldGroupName || !flexers[section.fieldGroupName]) {
          return null
        }

        const Component: React.FC<ComponentProps> =
          flexers[section.fieldGroupName]

        return (
          <Component
            // eslint-disable-next-line react/no-array-index-key
            key={`${location ? location.pathname : 'flex'}-${index}`}
            fields={section}
            location={location}
            pageContext={pageContext}
          />
        )
      })}
    </>
  )
}

export default FlexContentHandler
