import { useContext } from 'react'
import { PageContext, PageContextProps } from '../templates/page'
import { PostContext, PostContextProps } from '../templates/post'
import {
  GaragedoorContext,
  GaragedoorContextProps,
} from '../templates/garagedoor'

export type { PageContextProps, PostContextProps, GaragedoorContextProps }

const usePage = ():
  | PostContextProps
  | PageContextProps
  | GaragedoorContextProps => {
  const postContext = useContext(PostContext)
  const garagedoorContext = useContext(GaragedoorContext)

  if (postContext.exists) {
    return postContext as PostContextProps
  }

  if (garagedoorContext.exists) {
    return garagedoorContext as GaragedoorContextProps
  }

  const pageContext = useContext(PageContext)

  return pageContext as PageContextProps
}

export default usePage
