import React from 'react'
import { PageProps } from 'gatsby'

// Components
import Usps from './Usps'

export interface UspsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Usps
  location: PageProps['location']
}

interface PostProps {
  [key: string]: React.FC<UspsProps>
}

const UspsShell = ({ fields, location }: UspsProps) => {
  const types: PostProps = {
    default: Usps,
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default UspsShell
