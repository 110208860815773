import React from 'react'
import { compose, withProps } from 'recompose'
import { withScriptjs, GoogleMap, withGoogleMap } from 'react-google-maps'

// Utils
import mapStyleDark from 'utils/mapStyleDark.json'

// Images
import Marker from 'img/marker.inline.svg'

// Interface
import { ComponentProps } from 'components/flex/Component/ComponentShell'

const Map: React.FC<ComponentProps> = () => {
  return (
    <section id="Map" className="relative">
      <MapComponent />
    </section>
  )
}

export default Map

const MapComponent = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyBLlwLFu8W8-cLZzXHfmdOLkudG3Wwbnvs&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div className="h-[400px] md:h-[619px]" />,
    mapElement: <div style={{ height: '100%' }} />,
  }),
  withScriptjs,
  withGoogleMap
)(() => (
  <GoogleMap
    defaultOptions={{
      styles: mapStyleDark,
      fullscreenControl: false,
      satellite: false,
      zoomControl: false,
      streetViewControl: false,
      panControl: false,
      scrollwheel: false,
      draggable: false,
      mapTypeControl: false,
    }}
    defaultZoom={16}
    defaultCenter={{ lat: 52.2262919, lng: 5.366808 }}
  >
    <CustomMarker lat={52.2262919} lng={5.366808} title="My marker" />
  </GoogleMap>
))

const CustomMarker = (props: any) => (
  <div
    className="absolute top-1/2 left-1/2 h-[18px] w-[18px]"
    style={{
      transform: `translate(-50%, -50%)`,
    }}
    title={props.title}
  >
    <div className="relative">
      <div className="absolute">
        <div className="relative -top-6 -left-44 bg-secondary p-3 font-bold text-light sm:-left-80 sm:min-w-[289px] sm:py-4 sm:px-8">
          <a
            href="#"
            className="children:text-sm children:text-light sm:children:text-base"
          >
            <span>Amersfoortseweg 30-20</span>
            <span>3751 LK Bunschoten-Spakenburg</span>
          </a>
          <div className="absolute left-full top-1/2 -mt-6 inline-block w-8 overflow-hidden">
            <div className="h-12 origin-top-left rotate-45 transform bg-dark"></div>
          </div>
        </div>
      </div>
      <Marker />
    </div>
  </div>
)
