import React from 'react'

// Components
import RadioField from '../Fields/RadioFieldImage'
import StepHandler from '../Handlers/StepHandler'

// Interface
import { useFormDuuf } from '@ubo/form-duuf'

const Four = () => {
  const duuf = useFormDuuf()
  const enabled = !!duuf.formik.values.input_8

  return (
    <div className="mt-20">
      <h2 className="text-2xl font-bold">
        4. Kleur<span className="form-duuf-label-mandatory">*</span>
      </h2>
      <div>
        <RadioField id={8} type="square" imgFolder="color" extension="png" />
      </div>
      <StepHandler step={4} enabled={enabled} />
    </div>
  )
}

export default Four
