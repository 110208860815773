import React from 'react'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'

// Interface
import { FormProps } from 'components/flex/Form/FormShell'

const FormShowroom = ({ fields }: FormProps) => (
  <section id="FormShowroom">
    <div className="container flex justify-end py-14">
      <div className="lg:w-11/12">
        <div className="px-6 pt-14 pb-20 shadow-full lg:px-28">
          <FormDuuf
            id={2}
            generate={false}
            //captchaSiteKey="6LdcL5ggAAAAAOL0cXPyHYZq-esjlRdYioTl50sO"
          >
            <div className="grid grid-cols-2 gap-16">
              <div className="col-span-2 lg:col-span-1">
                <FormDuufGroup showIds={[2, 3, 4, 6, 7]} />
              </div>
              <div className="col-span-2 lg:col-span-1">
                <FormDuufGroup showIds={[8, 9, 10, 11]} />
                <div className="grid grid-cols-3">
                  <div className="col-span-3 xl:col-span-1">
                    <FormDuufSubmit />
                  </div>
                  <div className="col-span-3 mt-6 xl:col-span-2 xl:mt-0">
                    <span className="pl-18 -mt-3 block opacity-50">
                      Wij nemen zo snel mogelijk contact met u op om een
                      afspraak in te plannen.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </FormDuuf>
        </div>
      </div>
    </div>
  </section>
)

export default FormShowroom
