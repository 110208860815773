import React from 'react'
import { PageProps } from 'gatsby'

// Components
import BannerLanding from 'components/flex/Banner/BannerLanding'
import BannerContact from 'components/flex/Banner/BannerContact'
import BannerDefault from 'components/flex/Banner/BannerDefault'

export interface BannerProps {
  // eslint-disable-next-line camelcase
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  location: PageProps['location']
  pageContext: PageProps['pageContext']
}

interface BannersProps {
  [key: string]: React.FC<BannerProps>
}

const BannerShell = ({ fields, location, pageContext }: BannerProps) => {
  const banners: BannersProps = {
    landing: BannerLanding,
    contact: BannerContact,
    default: BannerDefault,
  }

  if (!fields.styletype || !banners[fields.styletype]) {
    return null
  }

  const Component = banners[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
      pageContext={pageContext}
    />
  )
}

export default BannerShell
