import React, { useState } from 'react'
import Plaatjie from '@ubo/plaatjie'

// Components
import NavLink from 'components/shared/NavLink'
import FormMalfunction from 'components/elements/FormMalfunction'

// Images
import Phone from 'img/phone.inline.svg'
import Alert from 'img/alert.inline.svg'

interface TopmenuProps {
  fields: GatsbyTypes.WpComponent_Header_TopmenuHeader
  isSticky?: boolean
}

const Topmenu = ({ fields, isSticky }: TopmenuProps) => {
  const [formOpen, setFormOpen] = useState(false)

  return (
    <>
      <div
        className={`${
          isSticky ? 'bg-greyLight text-secondary' : 'bg-secondary text-light'
        }`}
      >
        <div
          className={`container flex justify-between ${
            isSticky ? 'py-1' : 'py-3'
          }`}
        >
          <a
            href={fields.highlighted?.link?.url}
            className="hidden items-center hover:underline sm:flex"
            target="_blank"
          >
            <span>{fields.highlighted?.link?.title}</span>
            <Plaatjie
              image={fields.highlighted?.image}
              alt=""
              className="ml-4 mt-1"
            />
          </a>

          <div className="flex">
            <a
              className="mr-5 flex items-center hover:underline sm:mr-10"
              href={fields.phone?.url}
            >
              <Phone className="mr-3" />
              <span>{fields.phone?.title}</span>
            </a>
            <button
              className="flex items-center hover:underline"
              onClick={() => setFormOpen(true)}
            >
              <Alert className="mr-3" />
              <span>{fields.malfunction?.title}</span>
            </button>
          </div>
        </div>
      </div>
      <FormMalfunction
        description={fields.malfunctiondescription}
        isOpen={formOpen}
        closeModal={() => setFormOpen(false)}
      />
    </>
  )
}

export default Topmenu
