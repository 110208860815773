import React from 'react'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'

// Interface
import { TextProps } from 'components/flex/Text/TextShell'

const TextTitleImage = ({ fields }: TextProps) => (
  <section id="TextTitleImage" className="mb-12 lg:mb-28">
    <div className="container flex justify-end py-5">
      <div className="lg:w-11/12">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 sm:col-span-5">
            <ParseContent
              content={fields.title || ''}
              className="children:leading-tight xl:pr-40"
            />
          </div>
          <div className="col-span-12 sm:col-span-7">
            <Plaatjie image={fields.image} alt="" />
          </div>
        </div>
        <div className="relative sm:ml-24 lg:w-5/12">
          <div className="bg-primary py-6 px-4 sm:py-16 sm:px-12 lg:-mt-24 xl:-mt-60">
            <ParseContent
              content={fields.description || ''}
              className="p-leading-relaxed a-light"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default TextTitleImage
