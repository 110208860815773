import React, { useState } from 'react'
import Plaatjie from '@ubo/plaatjie'
import { motion } from 'framer-motion'

// Components
import NavLink from 'components/shared/NavLink'
import ParseContent from 'components/shared/ParseContent'

// Interface
import { TextProps } from 'components/flex/Text/TextShell'

const TextLinks = ({ fields }: TextProps) => {
  const [currentIndex, setCurrentIndex] = useState(-1)

  return (
    <section id="TextLinks" className="relative overflow-hidden pb-20">
      <div className="absolute -bottom-20 h-[300px] w-full bg-greyLight " />
      <div className="container flex justify-center py-10">
        <div className="flex-col md:w-5/6">
          <div className="flex flex-col pb-20 md:flex-row">
            <div className="md:w-1/2">
              <ParseContent content={fields.title || ''} className="text-6xl" />
            </div>
            <div className="md:w-1/2">
              <ParseContent
                content={fields.description || ''}
                className="children-a:hover:underline"
              />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row">
            {fields.links?.map((node, index) => (
              <motion.div
                className="relative ml-2 mb-5 overflow-hidden sm:mb-0 sm:w-1/2 md:ml-8"
                onHoverStart={() => setCurrentIndex(index)}
                onHoverEnd={() => setCurrentIndex(-1)}
                key={index}
              >
                <NavLink to={node?.link?.url || '#'} key={index}>
                  <motion.div
                    initial={{ scale: 1 }}
                    animate={
                      currentIndex === index ? { scale: 1.1 } : { scale: 1 }
                    }
                  >
                    <Plaatjie image={node?.image} alt="" />
                  </motion.div>
                  <div className="absolute bottom-0 w-full bg-gradient-to-b from-gradient-darkTo via-gradient-darkVia to-gradient-darkFrom px-6 py-5">
                    <span className="font-primary text-3xl font-bold tracking-wide text-light">
                      {node?.link?.title}
                    </span>
                  </div>
                </NavLink>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default TextLinks
