import React, { useState } from 'react'
import ReactModal from 'react-modal'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'
import ParseContent from 'components/shared/ParseContent'

// CSS
import 'styles/form.css'

// Interface
interface FormMalfunctionProps {
  description?: string
  isOpen: boolean
  closeModal: Function
}

const FormMalfunction: React.FC<FormMalfunctionProps> = ({
  description,
  isOpen,
  closeModal,
}) => {
  const [isCustomer, setIsCustomer] = useState(false)

  const customStyles = {
    overlay: {
      background: '#000',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      height: '100%',
      width: '100%',
      background: 'transparent',
      border: '0',
    },
  }

  return (
    <ReactModal
      id="FormMalfunction"
      isOpen={isOpen}
      // @ts-ignore
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      <div className="relative flex h-full items-center justify-center">
        <div className="absolute top-8 right-4 lg:top-12 lg:right-12">
          <button
            className="text-xl font-bold text-light hover:opacity-50 lg:text-6xl"
            onClick={() => closeModal(true)}
          >
            X
          </button>
        </div>
        <div className="pt-[600px] lg:w-[600px] lg:pt-32">
          <ParseContent
            content={description || ''}
            className="mb-16 text-light children-h2:mb-5 sm:children-p:px-20"
          />
          <div className="flex justify-center">
            <div className="px-4 sm:w-[420px] lg:px-0">
              <div className="mb-4 flex text-light">
                <div className="mr-8 ml-2 flex items-center">
                  <input
                    type="checkbox"
                    onClick={() => setIsCustomer(true)}
                    className={`mr-4 h-[20px] w-[20px] border-2`}
                    checked={isCustomer}
                  />
                  <span>Ik ben al klant</span>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    onClick={() => setIsCustomer(false)}
                    className={`mr-4 h-[20px] w-[20px] border-2`}
                    checked={!isCustomer}
                  />
                  <span>Ik ben nog geen klant</span>
                </div>
              </div>
              <FormDuuf
                id={3}
                generate={false}
                className="text-light"
                // captchaSiteKey="6LcTtE4eAAAAAOYrWnyZTzqgx1EYCrmK9VfX6-_w"
              >
                <FormInner isCustomer={isCustomer} />
              </FormDuuf>
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  )
}

const FormInner = ({ isCustomer }: { isCustomer: boolean }) => {
  return (
    <div>
      <FormDuufGroup showIds={[6]} />
      <FormDuufGroup showIds={[2, 3, 12, 4, 13]} />
      {!isCustomer && (
        <div>
          <h3 className="mb-4 text-lg font-bold">Factuurgegevens</h3>
          <FormDuufGroup showIds={[7, 8, 9]} />
          <div className="flex items-center">
            <div className="mr-2 w-1/2">
              <FormDuufGroup showIds={[10]} />
            </div>
            <div className="ml-2 w-1/2">
              <FormDuufGroup showIds={[11]} />
            </div>
          </div>
        </div>
      )}
      <div className="mt-8 flex justify-center pb-16">
        <FormDuufSubmit />
      </div>
    </div>
  )
}

export default FormMalfunction
