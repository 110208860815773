import React from 'react'
import { graphql, PageProps } from 'gatsby'

// Components
import SEO, { SeoProps } from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler from 'components/shared/FlexContentHandler'

export interface PageContextProps {
  exists: boolean
  title: string
  translations?: any
  locale: {
    locale: string
  }
  languages: []
}

export const PageContext = React.createContext({
  exists: false,
  title: '',
  translations: [],
  locale: {
    locale: 'nl_NL',
  },
  languages: [],
})

interface PageTemplateProps extends Omit<PageProps, 'data'> {
  data: {
    site: {
      languages: []
    }
    page: {
      title?: string
      path?: string
      seo: SeoProps
      flexContent: GatsbyTypes.Maybe<GatsbyTypes.WpFragment_FlexFragment>
      translations?: any
      locale: {
        locale: string
      }
    }
  }
  prefix?: string
}

const PageTemplate = ({
  data: {
    site,
    page: { title = '', flexContent, seo, translations, locale },
  },
  location,
  pageContext,
  prefix = 'page_Flexcontent',
}: PageTemplateProps) => (
  <PageContext.Provider
    value={{
      exists: true,
      title,
      translations,
      locale,
      languages: typeof site !== 'undefined' ? site.languages : [],
    }}
  >
    <Layout>
      <SEO seo={seo} />
      <FlexContentHandler
        prefix={prefix}
        fields={flexContent}
        location={location}
        pageContext={pageContext}
      />
    </Layout>
  </PageContext.Provider>
)

export const pageQuery = graphql`
  query pageById($pageId: String!) {
    page: wpPage(id: { eq: $pageId }) {
      ...generalPageFragment
    }

    site: wp {
      ...generalMultilanguageFragment
    }
  }
`

export default PageTemplate
