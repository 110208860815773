import React from 'react'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'

// Interface
import { TextProps } from 'components/flex/Text/TextShell'

const TextImage = ({ fields }: TextProps) => (
  <section id="TextImage" className="mb-28">
    <div className="container flex justify-end py-10">
      <div className="lg:w-11/12">
        <div className="grid sm:grid-cols-2">
          <div className="sm:col-span-1">
            <ParseContent
              content={fields.description || ''}
              className="children-h2:font-secondary children-h2:text-3xl children-h2:leading-tight children-h3:pb-0 children-h3:text-xl md:pr-16"
            />
          </div>
          <div className="mt-8 sm:col-span-1 lg:mt-0">
            <Plaatjie image={fields.image} alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default TextImage
