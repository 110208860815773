import React, { useState } from 'react'
import { motion } from 'framer-motion'

// Components
import Menu from 'components/layout/Header/Menu'
import MultiLanguage from 'components/related/Multilanguage'

// CSS
import 'styles/multilanguage.css'

interface DesktopProps {
  fields: GatsbyTypes.WpComponent
  isSticky?: boolean
}

const Desktop = ({ fields, isSticky }: DesktopProps) => {
  const [switchOpen, setSwitchOpen] = useState(false)

  return (
    <ul className="mb-0 hidden h-full w-full list-none items-center justify-end lg:flex">
      {/* @ts-ignore */}
      <Menu fields={fields} isSticky={isSticky} />
      <motion.div
        onHoverStart={() => setSwitchOpen(true)}
        onHoverEnd={() => setSwitchOpen(false)}
      >
        <MultiLanguage
          primaryLocale="nl_NL"
          type="dynamic"
          display={{ select: 'flags-native', switch: 'flags' }}
          isOpen={switchOpen}
        />
      </motion.div>
    </ul>
  )
}

export default Desktop
