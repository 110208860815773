import React, { ReactNode } from 'react'
import { PageProps } from 'gatsby'

// Components
import DoorTypes from './Taxonomies/DoorTypes'
import Profiling from './Taxonomies/Profiling'
import Overview from './Overview'

export interface GaragedoorsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Garagedoorz
  location: PageProps['location']
}

interface PostProps {
  [key: string]: React.FC<GaragedoorsProps>
}

const GaragedoorsShell = ({ fields, location }: GaragedoorsProps) => {
  const types: PostProps = {
    doortypes: DoorTypes,
    profiling: Profiling,
    overview: Overview,
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default GaragedoorsShell
