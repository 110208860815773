import React from 'react'
import { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Plaatjie from '@ubo/plaatjie'

// Components
import Button from 'components/elements/Button'
import ParseContent from 'components/shared/ParseContent'

// CSS
import 'swiper/css'
import 'swiper/css/pagination'

// Images
import Chevron from 'img/swiper-chevron.inline.svg'

// Hooks
import usePage from 'hooks/usePage'

// Interface
import { GaragedoorsProps } from '../GaragedoorShell'

const Profiling = ({ fields }: GaragedoorsProps) => {
  const page = usePage()

  // @ts-ignore
  const profiling = page.profiling.nodes

  return (
    <section id="Profiling" className="bg-greyLight pb-20">
      <div className="container flex justify-center">
        <div className="w-10/12 xl:w-11/12">
          <ParseContent content={fields.description || ''} />
          <div className="relative">
            <div
              className="custom-swiper-button-prev absolute -left-12 flex h-full items-center"
              role="button"
            >
              <Chevron className="rotate-180" />
            </div>
            <div
              className="custom-swiper-button-next absolute -right-12 flex h-full items-center"
              role="button"
            >
              <Chevron />
            </div>
            <Swiper
              breakpoints={{
                0: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                992: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
              }}
              modules={[Navigation]}
              navigation={{
                prevEl: '.custom-swiper-button-prev',
                nextEl: '.custom-swiper-button-next',
              }}
            >
              {profiling.map((profile: any, index: number) => (
                <SwiperSlide key={index} className="h-auto">
                  <div className="h-full bg-light p-2 sm:p-4">
                    <Plaatjie image={profile.recap.image} alt="" />
                    <h2 className="py-4 text-center text-sm font-bold sm:text-2xl">
                      {profile.name}
                    </h2>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="mt-20 flex justify-center">
            {fields.links?.map((node, index) => (
              <Button
                className="btn-secondary flex items-center"
                to={node?.link?.url || '#'}
                key={index}
              >
                <Plaatjie image={node?.icon} alt="" className="mr-5" />
                <span className="mb-1">{node?.link?.title}</span>
              </Button>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Profiling
