import React, { useMemo } from 'react'

// Functions
import { calculatePrice } from '../Functions/calculatePrice'

// Hooks
import { useFormDuuf } from '@ubo/form-duuf'
import useConfigurator from '../Hooks/useConfigurator'

const Specifications = () => {
  const duuf = useFormDuuf()
  const configurator = useConfigurator()
  const totalPrice = useMemo(
    () => calculatePrice(duuf.formik.values),
    [duuf.formik.values]
  )

  React.useEffect(() => {
    duuf.formik.setFieldValue('input_21', totalPrice, false)
  }, [totalPrice])

  const hasSpecs = totalPrice !== 'Op aanvraag'

  if (!hasSpecs) {
    return <div className="mt-20" />
  }

  return (
    <div className="relative mt-20 bg-gray-200 px-8">
      <div className="flex justify-start py-10">
        <div className="flex w-full flex-col items-center justify-center lg:w-11/12 lg:flex-row lg:justify-between">
          <h3 className="children:text-3xl children:font-bold ">
            {hasSpecs && <span>Totaal:</span>}
            <span className="ml-20">{totalPrice}</span>
          </h3>
          <div className="sm:order-first">
            {hasSpecs && (
              <>
                <h3 className="mt-6 font-bold lg:mt-0">
                  Specificaties van samengestelde deur
                </h3>
                <ul className="mt-6 list-disc pl-6">
                  {duuf.formik.values.input_2 && (
                    <li>{duuf.formik.values.input_2}</li>
                  )}
                  {duuf.formik.values.input_3 && (
                    <li>{duuf.formik.values.input_3}</li>
                  )}
                  {duuf.formik.values.input_5 && (
                    <li>{duuf.formik.values.input_5}</li>
                  )}
                  {duuf.formik.values.input_6 && (
                    <li>{duuf.formik.values.input_6}</li>
                  )}
                  {duuf.formik.values.input_7 && (
                    <li>{duuf.formik.values.input_7}</li>
                  )}
                  {duuf.formik.values.input_8 && (
                    <li>{duuf.formik.values.input_8}</li>
                  )}
                </ul>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="mt-2 pb-10 text-[12px]">
        Vrijblijvende adviesprijs voor de actiedeuren in de actiematen incl. 21
        % btw, standaard montage, aandrijving ProMatic en handzender, excl.
        demontage en afvoer oude deur. De prijzen zijn geldig bij alle
        deelnemende dealers. Afwijkende afmetingen of andere wensen op aanvraag.
        De afgebeelde kleuren en oppervlakken zijn niet kleurbindend. Alle
        kleurgegevens vergelijkbaar met de RAL-kleur. Wijzigingen en prijsfouten
        onder voorbehoud.
      </div>
    </div>
  )
}

export default Specifications
