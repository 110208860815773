import React, { useState } from 'react'
import { motion } from 'framer-motion'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'
import NavLink from 'components/shared/NavLink'

// Interface
import { HeroProps } from './HeroShell'

const Hero = ({ fields }: HeroProps) => {
  const [currentIndex, setCurrentIndex] = useState(-1)

  return (
    <section id="Hero" className="relative overflow-hidden">
      <div className="absolute z-20 h-full w-full bg-dark opacity-70 mix-blend-multiply" />
      <div className="absolute z-10 h-full w-full">
        <Plaatjie image={fields.background} alt="" />
      </div>
      <div className="container relative z-30 flex justify-center py-16">
        <div className="flex flex-col lg:w-5/6 lg:flex-row">
          <div className="mb-10 lg:mb-0 lg:w-1/2">
            <ParseContent
              content={fields.description || ''}
              className="xl:p-pl p-leading-relaxed text-light children-a:hover:underline lg:pr-20"
            />
          </div>
          <div className="lg:w-1/2">
            {fields.links?.map((node, index) => (
              <motion.div
                className="mb-6 h-[111px] bg-light hover:bg-greyLight"
                onHoverStart={() => setCurrentIndex(index)}
                onHoverEnd={() => setCurrentIndex(-1)}
                key={index}
              >
                <NavLink
                  to={node?.link?.url || '#'}
                  className="flex h-[111px] items-center py-6 px-4"
                  key={index}
                >
                  <motion.div
                    initial={{ scale: 1 }}
                    animate={
                      currentIndex === index ? { scale: 1.1 } : { scale: 1 }
                    }
                  >
                    <Plaatjie
                      image={node?.image}
                      alt=""
                      className="h-[85px] w-[130px]"
                    />
                  </motion.div>

                  <span className="ml-10 font-primary font-bold sm:text-3xl">
                    {node?.link?.title}
                  </span>
                </NavLink>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
