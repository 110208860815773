import React from 'react'
import { motion } from 'framer-motion'
import {
  FormDuufLabel,
  FormDuufError,
  prepareFormDuufField,
} from '@ubo/form-duuf'

// Formik
import { useFormikContext, FormikContextType } from 'formik'

// Functions
import { generateActiveColors } from '../Functions/generateActiveColors'
import { generateActiveSurfaces } from '../Functions/generateActiveSurfaces'
import useConfigurator from '../Hooks/useConfigurator'

interface RadioFieldsProps {
  id: number
  type: 'square' | 'rectangle'
  imgFolder?: string
  extension?: string
}

const RadioFieldImage: React.FC<RadioFieldsProps> = ({
  id,
  type,
  imgFolder,
  extension,
}) => {
  const config = useConfigurator()
  const formik: FormikContextType<{}> = useFormikContext<{}>()
  const field = prepareFormDuufField(id)
  let choices = JSON.parse(field.data.choices)
  const wrap: boolean = choices.length > 4

  if (id === 7) {
    const surfaces = generateActiveSurfaces(6, formik.values)
    const values: Array<string> = []
    surfaces?.forEach((surface) => values.push(surface.value))
    choices = choices.filter((choice: any) => values?.includes(choice.text))
  }

  if (id === 8) {
    const colors = generateActiveColors(id, formik.values)
    const values: Array<string> = []
    colors?.forEach((color) => values.push(color.value))
    choices = choices.filter((choice: any) => values?.includes(choice.text))
  }

  if (id === 7) {
    field.data.isRequired = true
  }

  const chosenIndex = choices.findIndex(
    (choice: any) =>
      // @ts-ignore
      formik.values && choice.value === formik.values[field.fieldId]
  )

  const [currentIndex, setCurrentIndex] = React.useState(-1)
  const [currentChoice, setCurrentChoice] = React.useState(chosenIndex)

  return (
    <div
      className={`form-duuf-group mb-12 form-duuf-group-${field.data.type} form-duuf-radio-${field.fieldId}`}
    >
      <FormDuufLabel fieldId={field.fieldId} data={field.data} />
      <FormDuufError
        fieldId={field.fieldId}
        data={field.data}
        hasError={!!field.hasError}
        formik={formik}
      />
      <div
        className={`form-duuf-choices-${field.fieldId} mt-4 ${
          wrap ? 'grid grid-cols-2 pr-12 lg:grid-cols-3' : ' flex flex-wrap '
        }`}
      >
        {choices.map((choice: any, choiceIndex: number) => {
          const fileName = `${choice.text.replace(/\s+/g, '-').toLowerCase()}`

          let imgSrc: string

          if (extension) {
            imgSrc = `/img/${imgFolder}/${fileName}.${extension}`
          } else {
            imgSrc = `/img/${imgFolder}/${fileName}.jpg`
          }

          return (
            <motion.div
              className={`${
                wrap ? 'col-span-1 flex flex-col items-center ' : ''
              }relative mb-8 ${type === 'square' ? 'p-4' : 'max-w-[110px]'}`}
              key={choice.value}
              onHoverStart={() => setCurrentIndex(choiceIndex)}
              onHoverEnd={() => setCurrentIndex(-1)}
              onClick={() => setCurrentChoice(choiceIndex)}
            >
              <input
                type="radio"
                className={` form-duuf-checkbox absolute top-0 left-0 form-duuf-checkbox-${field.data.type} h-full w-full appearance-none rounded-none border-0 bg-transparent`}
                id={choice.value}
                name={field.fieldId}
                placeholder={field.data.placeholder}
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  formik.handleChange({
                    target: {
                      name: event.target.name,
                      value: event.target.value,
                    },
                  })
                }}
                value={choice.value}
              />
              {type === 'square' && (
                <motion.div
                  style={{
                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
                    width: 96,
                    height: 96,
                  }}
                  initial={{ border: '3px solid transparent' }}
                  animate={
                    currentIndex === choiceIndex ||
                    currentChoice === choiceIndex
                      ? { border: '3px solid #000' }
                      : { border: '3px solid transparent ' }
                  }
                  className={`mx-auto w-full p-2`}
                >
                  <img
                    src={`${config.startUrl}${imgSrc}`}
                    alt=""
                    width="100%"
                    className="aspect-square"
                    style={{ height: 74, width: 74 }}
                  />
                </motion.div>
              )}
              {type === 'rectangle' && (
                <motion.div
                  initial={{ border: '3px solid transparent' }}
                  animate={
                    currentIndex === choiceIndex ||
                    currentChoice === choiceIndex
                      ? { border: '3px solid #000' }
                      : { border: '3px solid transparent' }
                  }
                >
                  <img
                    src={`${config.startUrl}${imgSrc}`}
                    alt=""
                    style={{
                      minHeight: 130,
                      minWidth: 100,
                      width: '100%',
                      aspectRatio: '4/5',
                    }}
                  />
                </motion.div>
              )}

              <label
                htmlFor={choice.value}
                className={`
                form-duuf-checkbox-label relative mt-4 block w-full text-center text-[12px] form-duuf-checkbox-${field.data.type}`}
              >
                {choice.text}
              </label>
            </motion.div>
          )
        })}
      </div>
    </div>
  )
}

export default RadioFieldImage
