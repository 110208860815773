import React from 'react'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'

// Interface
import { CtaProps } from './CtaShell'

const Cta = ({ fields }: CtaProps) => (
  <section id="Cta" className="relative">
    <div className="absolute bottom-0 h-full w-full bg-greyLight xl:h-3/4"></div>
    <div className="container relative flex justify-end pt-10 sm:pt-0">
      <div className="lg:w-11/12">
        <div className="grid grid-cols-12">
          <div className="col-span-12 xl:col-span-7">
            <div className="flex h-full items-center sm:pt-40">
              <ParseContent
                content={fields.description || ''}
                className="h-text-6xl children-a:hover:underline"
              />
            </div>
          </div>
          <div className="relative bottom-0 right-0 col-span-12 mt-10 flex justify-end xl:top-0 xl:col-span-5 xl:mt-0">
            <div className="relative">
              <div className="absolute -left-[140px] sm:-left-[200px] xl:top-48 xl:-left-44">
                <div className="relative bg-primary px-6 py-4">
                  <ParseContent
                    content={fields.balloon || ''}
                    className="tracking-wide text-light children:font-primary children:text-sm sm:children:text-2xl"
                  />
                  <div className="absolute -right-4 top-5 inline-block w-4 overflow-hidden">
                    <div className="h-6 origin-top-left rotate-45 transform bg-primary" />
                  </div>
                </div>
              </div>
              <Plaatjie
                image={fields.image}
                alt=""
                className="max-h-[200px] max-w-[180px] xl:max-h-full xl:max-w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Cta
