import React, { useState } from 'react'
import Plaatjie from '@ubo/plaatjie'
import { Link } from 'gatsby'

// Components
import ParseContent from 'components/shared/ParseContent'
import Crumb from 'components/elements/Breadcrumb'
import FormDuuf from 'components/related/Form'

// Interface
import { BannerProps } from 'components/flex/Banner/BannerShell'
import FormMalfunction from 'components/elements/FormMalfunction'

const BannerContact = ({ fields, location, pageContext }: BannerProps) => {
  const [formOpen, setFormOpen] = useState(false)

  return (
    <section id="BannerContact">
      <div className="bg-black">
        <div className="container relative z-10 pt-36 pb-20 lg:pb-36 lg:pt-52">
          <h1 className="text-center font-primary text-6xl font-bold text-light">
            Contact
          </h1>
          <div className="flex justify-center">
            <Crumb location={location} pageContext={pageContext} />
          </div>
          <div className="flex flex-col justify-center py-10 md:flex-row md:py-20">
            {fields.contactinfo?.map((item, index) => (
              <div
                key={index}
                className="pb-6 last:pb-0 md:min-w-[200px] md:pb-0 lg:min-w-[275px]"
              >
                {item?.icon && (
                  <div className="flex justify-center pb-3">
                    <Plaatjie image={item.icon} alt="" />
                  </div>
                )}
                <ParseContent
                  className="inner-a-light leading-7 text-light children-a:justify-center"
                  content={item?.description || ''}
                />
              </div>
            ))}
          </div>
          <div className="flex justify-center">
            <div className="g-5 grid grid-cols-2 bg-light p-6 sm:w-9/12 lg:p-14">
              <div className="col-span-2 flex flex-col justify-between first:pb-8 md:col-span-1 md:first:pb-0 lg:pr-20">
                <ParseContent content={fields.contactcta?.description || ''} />
                <div>
                  {fields.contactcta?.buttons?.map((item, index) => {
                    if (item?.link?.url?.includes('storing-melden')) {
                      return (
                        <div className="mt-4" key={index}>
                          <div
                            role="button"
                            className="btn-primary flex max-w-[210px]"
                            onClick={() => setFormOpen(true)}
                          >
                            <Plaatjie
                              className="mr-3 pt-1"
                              image={item?.icon}
                              alt=""
                            />
                            {item?.link?.title}
                          </div>
                        </div>
                      )
                    }
                    return (
                      <div className="mt-4">
                        <Link
                          to={item?.link?.url || '/'}
                          key={index}
                          className="btn-primary flex max-w-[210px]"
                        >
                          <Plaatjie
                            className="mr-3 pt-1"
                            image={item?.icon}
                            alt=""
                          />
                          {item?.link?.title}
                        </Link>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="col-span-2 md:col-span-1">
                <FormDuuf
                  //captchaSiteKey="6LcTtE4eAAAAAOYrWnyZTzqgx1EYCrmK9VfX6-_w"
                  id={parseInt(fields.contactformid || '1')}
                  generate
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <FormMalfunction
        description="<h2 style='text-align: center;'><strong>IK WIL EEN STORING MELDEN</strong></h2>
        <p style='text-align: center;'>
        Vul hieronder het formulier in en wij nemen binnen 2 uur contact met u. Zo zorgen we ervoor dat de storing zo snel mogelijk wordt opgelost.
        </p>
        "
        isOpen={formOpen}
        closeModal={() => setFormOpen(false)}
      />
    </section>
  )
}

export default BannerContact
