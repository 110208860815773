import { distribution } from '../dist'

export const getImagePath = (values: any) => {
  let path: Array<{ index: number; asset: string }> = []

  Object.keys(values).forEach((id) => {
    const field = distribution.fields.find((field) => field.id === id)

    if (
      !field ||
      typeof field.imgPathIndex !== `number` ||
      !field.options ||
      field.imgPathIndex === -1
    ) {
      return
    }

    const options = field?.options

    const selected = options?.find((option) => option.value === values[id])

    if (!selected) {
      return
    }

    path.push({
      index: field.imgPathIndex,
      // @ts-ignore
      asset: selected.asset,
    })
  })

  path = path.sort((node) => node.index)

  let imgPath = `${path
    .map((node) => node.asset)
    .join('-')
    .replace(/\s+/g, '-')}`

  if (imgPath === '') {
    return 'woodgrain-verkeerswit.jpg'
  }

  return `${imgPath}.jpg`
}
