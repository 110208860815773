import React from 'react'
import { PageProps } from 'gatsby'

// Components
import Highlighted from 'components/flex/Projects/Highlighted'
import Overview from './Overview'

export interface ProjectsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpProject_Flexcontent_Flex_Projects
  location?: PageProps['location']
}

interface ProjectProps {
  [key: string]: React.FC<ProjectsProps>
}

const ProjectsShell = ({ fields, location }: ProjectsProps) => {
  const types: ProjectProps = {
    highlighted: Highlighted,
    overview: Overview,
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default ProjectsShell
