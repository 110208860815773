import React from 'react'
import { motion } from 'framer-motion'
import {
  FormDuufFieldProps,
  FormDuufLabel,
  FormDuufError,
  prepareFormDuufField,
  useFormDuuf,
} from '@ubo/form-duuf'

// Formik
import { useFormikContext, FormikContextType } from 'formik'

interface RadioFieldsProps {
  id: number
}

const RadioFieldText: React.FC<RadioFieldsProps> = ({ id }) => {
  const formik: FormikContextType<{}> = useFormikContext<{}>()
  const field = prepareFormDuufField(id)
  const choices = JSON.parse(field.data.choices)

  const chosenIndex = choices.findIndex(
    (choice: any) =>
      // @ts-ignore
      formik.values && choice.value === formik.values[field.fieldId]
  )

  const [currentChoice, setCurrentChoice] = React.useState(chosenIndex)
  const [currentIndex, setCurrentIndex] = React.useState(-1)

  return (
    <div
      className={`form-duuf-group form-duuf-group-${field.data.type} form-duuf-radio-${field.fieldId}`}
    >
      <FormDuufLabel fieldId={field.fieldId} data={field.data} />
      <FormDuufError
        fieldId={field.fieldId}
        data={field.data}
        hasError={!!field.hasError}
        formik={formik}
      />
      <div
        className={`form-duuf-choices-${field.fieldId} grid grid-cols-2 gap-x-8`}
      >
        {choices.map((choice: any, choiceIndex: number) => {
          return (
            <motion.div
              className="text-radio relative col-span-1 mb-4 px-2 py-4"
              style={{ boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)' }}
              onHoverStart={() => setCurrentIndex(choiceIndex)}
              onHoverEnd={() => setCurrentIndex(-1)}
              onClick={() => setCurrentChoice(choiceIndex)}
              key={choice.value}
              initial={{ border: '3px solid transparent' }}
              animate={
                currentIndex === choiceIndex || currentChoice === choiceIndex
                  ? { border: '3px solid #000', background: '#e3e3e3' }
                  : {
                      border: '3px solid transparent',
                      background: 'transparent',
                    }
              }
            >
              <input
                type="radio"
                className={`
                form-duuf-checkbox absolute top-0 left-0 h-full w-full rounded-none border-0 bg-transparent form-duuf-checkbox-${field.data.type}`}
                id={choice.value}
                name={field.fieldId}
                placeholder={field.data.placeholder}
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  formik.handleChange({
                    target: {
                      name: event.target.name,
                      value: event.target.value,
                    },
                  })
                }}
                value={choice.value}
              />
              <label
                htmlFor={choice.value}
                className={`form-duuf-checkbox-label relative font-bold form-duuf-checkbox-${field.data.type}`}
              >
                {choice.text}
              </label>
            </motion.div>
          )
        })}
      </div>
    </div>
  )
}

export default RadioFieldText
