import React from 'react'

// Components
import Button from 'components/elements/Button'

// Hooks
import useConfigurator from '../Hooks/useConfigurator'
import { FormDuufSubmit } from '@ubo/form-duuf'

interface StepHandlerProps {
  step: number
  enabled: boolean
}

const StepHandler: React.FC<StepHandlerProps> = ({ step, enabled }) => {
  const config = useConfigurator()

  const handleStep = () => {
    if (!config.quotation) {
      config.setCurrentStep(step)
    } else {
      config.setCurrentStep(4)
    }
  }

  return (
    <div className="mt-12 flex items-center justify-between">
      <Button
        to="#"
        className={`btn-primary-custom ${
          enabled ? 'opacity-100' : 'opacity-20 hover:bg-primary'
        }`}
        isCustom
      >
        {config.currentStep !== 4 ? (
          <button
            onClick={handleStep}
            className={`px-4 font-bold`}
            disabled={!enabled}
          >
            {!config.quotation ? (
              <span>Volgende</span>
            ) : (
              <span>Offerte aanvragen</span>
            )}
          </button>
        ) : (
          <FormDuufSubmit />
        )}
      </Button>

      <div className="text-[12px]">
        <span className="mr-2 text-sm text-primary">*</span>Zijn verplichte
        velden
      </div>
    </div>
  )
}

export default StepHandler
